// 🛑 NOTICE: __generated__ folders should be added to .gitignore
import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import { FlightFragment } from '../../../passengers/gql/__generated__/FlightFragment.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllFlightsVariables = Types.Exact<{
  req?: Types.InputMaybe<Types.GetAllFlightsRequest_Input>;
}>;


export type GetAllFlights = { __typename: 'Query', flights_getAll?: { __typename: 'GetAllFlightsResponse', flights: Array<{ __typename: 'Flight', id: string, partner: string, freeSeats: number, status?: string | null, bookFields: Array<Types.EBookFields | null>, animals?: string | null, vehicleId?: string | null, luggage?: string | null, fromId: string, toId: string, pickupStopsIds: Array<string | null>, dischargeStopsIds: Array<string | null>, validBefore?: number | null, onlineRefund?: boolean | null, rideNumber?: number | null, saleTypes: Array<string | null>, ticketLimit?: number | null, carrierId: string, paymentsType: Array<string | null>, benefits: Array<string | null>, routeName?: string | null, atlasMeta?: string | null, rideType?: Types.EFlightType | null, routeId: string, freighter?: string | null, partnerName: string, partnerPhone?: string | null, partnerEmail?: string | null, refundConditions?: string | null, flightPopular?: number | null, connector: string, driver1?: string | null, driver2?: string | null, driver3?: string | null, ownerId: string, name: string, carrier: string, onlinePrice?: number | null, carrierPhones: Array<string | null>, fee: number, price: number, number?: string | null, currency: string, arrivalTime: string, departureTime: string, dynamicMode?: boolean | null, dynamicConfig?: string | null, seatingScheme: Array<string | null>, seatingRequired?: boolean | null, distance?: number | null, rulesLink?: string | null } | null> } | null };


export const GetAllFlightsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAllFlights"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"req"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"GetAllFlightsRequest_Input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"flights_getAll"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"req"},"value":{"kind":"Variable","name":{"kind":"Name","value":"req"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"flights"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"FlightFragment"}}]}}]}}]}},...FlightFragment.definitions]} as unknown as DocumentNode;

/**
 * __useGetAllFlights__
 *
 * To run a query within a React component, call `useGetAllFlights` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFlights` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFlights({
 *   variables: {
 *      req: // value for 'req'
 *   },
 * });
 */
export function useGetAllFlights(baseOptions?: Apollo.QueryHookOptions<GetAllFlights, GetAllFlightsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllFlights, GetAllFlightsVariables>(GetAllFlightsDocument, options);
      }
export function useGetAllFlightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllFlights, GetAllFlightsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllFlights, GetAllFlightsVariables>(GetAllFlightsDocument, options);
        }
export function useGetAllFlightsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllFlights, GetAllFlightsVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllFlights, GetAllFlightsVariables>(GetAllFlightsDocument, options);
        }
export type GetAllFlightsHookResult = ReturnType<typeof useGetAllFlights>;
export type GetAllFlightsLazyQueryHookResult = ReturnType<typeof useGetAllFlightsLazyQuery>;
export type GetAllFlightsSuspenseQueryHookResult = ReturnType<typeof useGetAllFlightsSuspenseQuery>;
export type GetAllFlightsQueryResult = Apollo.QueryResult<GetAllFlights, GetAllFlightsVariables>;