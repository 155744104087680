export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type Action = {
  __typename?: 'Action';
  description?: Maybe<Scalars['String']['output']>;
  procedure: EProcedureType;
  value: Scalars['Float']['output'];
};

export type Action_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  procedure: EProcedureType_Input;
  value: Scalars['Float']['input'];
};

export type ActivateRouteRequest_Input = {
  id: Scalars['String']['input'];
};

export type ActivateRouteResponse = {
  __typename?: 'ActivateRouteResponse';
  route: Route;
};

export type AddAmenityRequest_Input = {
  amenityId: Scalars['String']['input'];
  vehicleId: Scalars['String']['input'];
};

export type AddAmenityResponse = {
  __typename?: 'AddAmenityResponse';
  amenityId: Scalars['String']['output'];
};

export type AddCarrierToUserRequest_Input = {
  carrierId: Scalars['String']['input'];
  userEmail: Scalars['String']['input'];
};

export type AddCarrierToUserResponse = {
  __typename?: 'AddCarrierToUserResponse';
  success: Scalars['Boolean']['output'];
};

export type AddRouteRefundGroupRequest_Input = {
  refundGroupId: Scalars['String']['input'];
  routeId: Scalars['String']['input'];
  tariffId: Scalars['String']['input'];
};

export type AddRouteRefundGroupResponse = {
  __typename?: 'AddRouteRefundGroupResponse';
  routeRefundRule: RouteRefundRule;
};

export type AddRouteTariffRequest_Input = {
  routeId: Scalars['String']['input'];
  tariffId: Scalars['String']['input'];
};

export type AddRouteTariffResponse = {
  __typename?: 'AddRouteTariffResponse';
  rules?: Maybe<Array<Maybe<RouteRefundRule>>>;
};

export type AddTenantCarrierRequest_Input = {
  carrierId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type AddTenantCarrierResponse = {
  __typename?: 'AddTenantCarrierResponse';
  carrierId: Scalars['String']['output'];
  tenantId: Scalars['String']['output'];
};

export type AddTenantRoutesRequest_Input = {
  routeId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type AddTenantRoutesResponse = {
  __typename?: 'AddTenantRoutesResponse';
  tenantRoute: TenantRoutes;
};

export type AddTenantToUserRequest_Input = {
  tenantId: Scalars['String']['input'];
  userEmail: Scalars['String']['input'];
};

export type AddTenantToUserResponse = {
  __typename?: 'AddTenantToUserResponse';
  success: Scalars['Boolean']['output'];
};

export type Agent = {
  __typename?: 'Agent';
  counterpartyId: Scalars['String']['output'];
  saasId: Scalars['String']['output'];
};

export type Agent_Input = {
  counterpartyId: Scalars['String']['input'];
  customerId?: InputMaybe<Scalars['String']['input']>;
  saasId: Scalars['String']['input'];
};

export type Amenity = {
  __typename?: 'Amenity';
  amenity: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  vehicleType: VehicleType;
};

export type BatchCreateRoutesRequest_Input = {
  routes: Array<InputMaybe<CreateRouteRequest_Input>>;
};

export type BatchCreateRoutesResponse = {
  __typename?: 'BatchCreateRoutesResponse';
  routes: Array<Maybe<Route>>;
};

export type BatchRemoveRoutesRequest_Input = {
  ids: Array<InputMaybe<Scalars['String']['input']>>;
};

export type BatchRemoveRoutesResponse = {
  __typename?: 'BatchRemoveRoutesResponse';
  ids: Array<Maybe<Scalars['String']['output']>>;
};

export type BatchRouteLeg_Input = {
  arrivalPointId: Scalars['Int']['input'];
  departurePointId: Scalars['Int']['input'];
  routeId: Scalars['String']['input'];
};

export type BatchUpdateRoutesRequest_Input = {
  routes: Array<InputMaybe<UpdateRouteRequest_Input>>;
};

export type BatchUpdateRoutesResponse = {
  __typename?: 'BatchUpdateRoutesResponse';
  routes: Array<Maybe<Route>>;
};

export type BookOrderRequest_Input = {
  agent?: InputMaybe<Agent_Input>;
  arrivalStationId: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  dispatchStationId: Scalars['String']['input'];
  flightId: Scalars['String']['input'];
  salePayload: Array<InputMaybe<SalePayload_Input>>;
};

export type BookOrderResponse = {
  __typename?: 'BookOrderResponse';
  lifetime: Scalars['Int']['output'];
  orderId: Scalars['String']['output'];
};

export type BookingAvailability = {
  __typename?: 'BookingAvailability';
  chance: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export type CalcTicketReturnRequest_Input = {
  ticketId: Scalars['String']['input'];
};

export type CalcTicketReturnResponse = {
  __typename?: 'CalcTicketReturnResponse';
  available: Scalars['Boolean']['output'];
  price: Scalars['Float']['output'];
};

export type CalculateCostRequest_Input = {
  currency: Scalars['String']['input'];
  legCost: Scalars['Float']['input'];
  sales: Array<InputMaybe<SalePayload_Input>>;
  tariffs: Array<InputMaybe<Tariff_Input>>;
};

export type CalculateCostResponse = {
  __typename?: 'CalculateCostResponse';
  orderPrice: Scalars['Float']['output'];
  ticketPrices: Array<Maybe<TicketPrice>>;
};

export type CancelFlightRequest_Input = {
  id: Scalars['String']['input'];
};

export type CancelFlightResponse = {
  __typename?: 'CancelFlightResponse';
  id: Scalars['String']['output'];
  status: EFlightStatus;
};

export type CancelTicketRequest_Input = {
  id: Scalars['String']['input'];
};

export type CancelTicketResponse = {
  __typename?: 'CancelTicketResponse';
  ticket: Ticket;
};

export type Carrier = {
  __typename?: 'Carrier';
  counterpartyId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  egisOTBId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  operatingArea?: Maybe<Scalars['String']['output']>;
  supportEmail?: Maybe<Scalars['String']['output']>;
  supportHours?: Maybe<Scalars['String']['output']>;
  supportPhone?: Maybe<Scalars['String']['output']>;
  tenants: Array<Maybe<Scalars['String']['output']>>;
  updatedAt: Scalars['String']['output'];
};

export type ChangeFlightVisibilityRequest_Input = {
  id: Scalars['String']['input'];
  isHidden: Scalars['Boolean']['input'];
};

export type ChangeFlightVisibilityResponse = {
  __typename?: 'ChangeFlightVisibilityResponse';
  id: Scalars['String']['output'];
  isHidden: Scalars['Boolean']['output'];
};

export type ChangeInventoryStatusRequest_Input = {
  flightId: Scalars['String']['input'];
  legId: Scalars['String']['input'];
  seatNumber: Scalars['Int']['input'];
  status: EInventoryStatus_Input;
};

export type ChangeInventoryStatusResponse = {
  __typename?: 'ChangeInventoryStatusResponse';
  inventory: Array<Maybe<Inventory>>;
};

export type ChangeRouteRefundRuleRequest_Input = {
  id: Scalars['String']['input'];
  refundGroupId: Scalars['String']['input'];
};

export type ChangeStatusByDriverRequest_Input = {
  status: EOrderStatus_Input;
  ticketId: Scalars['String']['input'];
};

export type ChangeStatusByDriverResponse = {
  __typename?: 'ChangeStatusByDriverResponse';
  ticket: Ticket;
};

export type CheckAccessRequest_Input = {
  routeId: Scalars['String']['input'];
  tenants: Array<InputMaybe<Scalars['String']['input']>>;
};

export type CheckAccessResponse = {
  __typename?: 'CheckAccessResponse';
  ok: Scalars['Boolean']['output'];
};

export type Condition = {
  __typename?: 'Condition';
  afterFlightStart: Scalars['Boolean']['output'];
  countdown: ECountdownType;
  rangeFrom?: Maybe<Scalars['Int']['output']>;
  rangeTo?: Maybe<Scalars['Int']['output']>;
};

export type Condition_Input = {
  afterFlightStart: Scalars['Boolean']['input'];
  countdown: ECountdownType_Input;
  rangeFrom?: InputMaybe<Scalars['Int']['input']>;
  rangeTo?: InputMaybe<Scalars['Int']['input']>;
};

export type ConfirmOrderRequest_Input = {
  agent?: InputMaybe<Agent_Input>;
  orderId: Scalars['String']['input'];
  paymentMethod: EPaymentMethod_Input;
  ticketFare?: InputMaybe<TicketFare_Input>;
};

export type ConfirmOrderResponse = {
  __typename?: 'ConfirmOrderResponse';
  flightId: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  tickets: Array<Maybe<Ticket>>;
};

export type Contractor = {
  __typename?: 'Contractor';
  addresses: Array<Maybe<ContractorAddress>>;
  country: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  emails: Array<Maybe<ContractorEmail>>;
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  legalEntityForm: Scalars['String']['output'];
  midName: Scalars['String']['output'];
  phones: Array<Maybe<ContractorPhone>>;
  updatedAt: Scalars['String']['output'];
};

export type ContractorAddress = {
  __typename?: 'ContractorAddress';
  address: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isActual: Scalars['Boolean']['output'];
  updatedAt: Scalars['String']['output'];
};

export type ContractorEmail = {
  __typename?: 'ContractorEmail';
  createdAt: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isActual: Scalars['Boolean']['output'];
  updatedAt: Scalars['String']['output'];
};

export type ContractorPhone = {
  __typename?: 'ContractorPhone';
  createdAt: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isActual: Scalars['Boolean']['output'];
  phoneNumber: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type Coordinates = {
  __typename?: 'Coordinates';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type Coordinates2 = {
  __typename?: 'Coordinates2';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type Counterparty = {
  __typename?: 'Counterparty';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  bankAccountNumber?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  billingId?: Maybe<Scalars['Int']['output']>;
  branchAddress1?: Maybe<Scalars['String']['output']>;
  branchAddress2?: Maybe<Scalars['String']['output']>;
  branchCity?: Maybe<Scalars['String']['output']>;
  branchCountry?: Maybe<Scalars['String']['output']>;
  branchName?: Maybe<Scalars['String']['output']>;
  branchStateProvince?: Maybe<Scalars['String']['output']>;
  branchZipCode?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  registrationAuthority?: Maybe<Scalars['String']['output']>;
  registrationDate?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  stateProvince?: Maybe<Scalars['String']['output']>;
  swiftBic?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type CreateAmenityRequest_Input = {
  amenity: Scalars['String']['input'];
  description: Scalars['String']['input'];
  vehicleType: VehicleType_Input;
};

export type CreateAmenityResponse = {
  __typename?: 'CreateAmenityResponse';
  amenity: Amenity;
};

export type CreateCarrierRequest_Input = {
  counterpartyId: Scalars['String']['input'];
  egisOTBId?: InputMaybe<Scalars['Int']['input']>;
  operatingArea?: InputMaybe<Scalars['String']['input']>;
  supportEmail?: InputMaybe<Scalars['String']['input']>;
  supportHours?: InputMaybe<Scalars['String']['input']>;
  supportPhone?: InputMaybe<Scalars['String']['input']>;
  tenants: Array<InputMaybe<Scalars['String']['input']>>;
};

export type CreateCarrierResponse = {
  __typename?: 'CreateCarrierResponse';
  carrier: Carrier;
};

export type CreateContractorRequest_Input = {
  addresses: Array<InputMaybe<Scalars['String']['input']>>;
  country: Scalars['String']['input'];
  emails: Array<InputMaybe<Scalars['String']['input']>>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  legalEntityForm: Scalars['String']['input'];
  midName: Scalars['String']['input'];
  phones: Array<InputMaybe<Scalars['String']['input']>>;
};

export type CreateContractorResponse = {
  __typename?: 'CreateContractorResponse';
  contractor: Contractor;
};

export type CreateCounterpartyRequest_Input = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  billingId?: InputMaybe<Scalars['Int']['input']>;
  branchAddress1?: InputMaybe<Scalars['String']['input']>;
  branchAddress2?: InputMaybe<Scalars['String']['input']>;
  branchCity?: InputMaybe<Scalars['String']['input']>;
  branchCountry?: InputMaybe<Scalars['String']['input']>;
  branchName?: InputMaybe<Scalars['String']['input']>;
  branchStateProvince?: InputMaybe<Scalars['String']['input']>;
  branchZipCode?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  registrationAuthority?: InputMaybe<Scalars['String']['input']>;
  registrationDate?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  stateProvince?: InputMaybe<Scalars['String']['input']>;
  swiftBic?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCounterpartyResponse = {
  __typename?: 'CreateCounterpartyResponse';
  counterparty: Counterparty;
};

export type CreateCurrencyRequest_Input = {
  code: Scalars['String']['input'];
  exchangeRate?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  symbol: Scalars['String']['input'];
};

export type CreateCurrencyResponse = {
  __typename?: 'CreateCurrencyResponse';
  currency: Currency;
};

export type CreateCustomerRequest_Input = {
  agentCustomerId: Scalars['String']['input'];
  agentId: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
};

export type CreateCustomerResponse = {
  __typename?: 'CreateCustomerResponse';
  customer: Customer;
};

export type CreateDocumentTypeRequest_Input = {
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateDocumentTypeResponse = {
  __typename?: 'CreateDocumentTypeResponse';
  documentType: DocumentType;
};

export type CreateDriverRequest_Input = {
  birthday?: InputMaybe<Scalars['String']['input']>;
  carrierId?: InputMaybe<Scalars['String']['input']>;
  documentId?: InputMaybe<Scalars['Int']['input']>;
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gender: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  midName?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  ownerId: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type CreateDriverResponse = {
  __typename?: 'CreateDriverResponse';
  driver: Driver;
};

export type CreateEgisRegistryEntryRequest_Input = {
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  registryRegNumber: Scalars['String']['input'];
  routeId: Scalars['String']['input'];
};

export type CreateEgisRegistryEntryResponse = {
  __typename?: 'CreateEgisRegistryEntryResponse';
  entry: EgisRegistryEntry;
};

export type CreateFlightBatchAsyncRequest_Input = {
  flights?: InputMaybe<Array<InputMaybe<CreateFlightRequest_Input>>>;
};

export type CreateFlightBatchAsyncResponse = {
  __typename?: 'CreateFlightBatchAsyncResponse';
  jobId: Scalars['String']['output'];
};

export type CreateFlightEgisScheduleRequest_Input = {
  dayOfWeek: Scalars['Int']['input'];
  egisRegistryId: Scalars['String']['input'];
  endTime: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  routeId: Scalars['String']['input'];
  startTime: Scalars['String']['input'];
};

export type CreateFlightEgisScheduleResponse = {
  __typename?: 'CreateFlightEgisScheduleResponse';
  flightEgisSchedule: FlightEgisSchedule;
};

export type CreateFlightRequest_Input = {
  carrierId: Scalars['String']['input'];
  departureTime: Scalars['String']['input'];
  driver1?: InputMaybe<Scalars['String']['input']>;
  driver2?: InputMaybe<Scalars['String']['input']>;
  driver3?: InputMaybe<Scalars['String']['input']>;
  ownerId: Scalars['String']['input'];
  rideNumber?: InputMaybe<Scalars['Int']['input']>;
  rideType: EFlightType_Input;
  routeId: Scalars['String']['input'];
  vehicleId: Scalars['String']['input'];
};

export type CreateFlightResponse = {
  __typename?: 'CreateFlightResponse';
  flight?: Maybe<Flight>;
};

export type CreateInventorForFlightRequest_Input = {
  flightId: Scalars['String']['input'];
};

export type CreateInventoryForFlightResponse = {
  __typename?: 'CreateInventoryForFlightResponse';
  taskId: Scalars['Int']['output'];
};

export type CreateLegCostRequest_Input = {
  cost?: InputMaybe<Scalars['Float']['input']>;
  currencyId: Scalars['String']['input'];
  legId: Scalars['String']['input'];
};

export type CreateLegCostResponse = {
  __typename?: 'CreateLegCostResponse';
  legCost: LegCost;
};

export type CreatePassengerRequest_Input = {
  birthdayYear?: InputMaybe<Scalars['Int']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['String']['input'];
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  documentTypeId?: InputMaybe<Scalars['Int']['input']>;
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePassengerResponse = {
  __typename?: 'CreatePassengerResponse';
  passenger: Passenger;
};

export type CreateRefundGroupRequest_Input = {
  counterpartyId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateRefundGroupResponse = {
  __typename?: 'CreateRefundGroupResponse';
  refundGroup: RefundGroup;
};

export type CreateRefundRuleRequest_Input = {
  action: Action_Input;
  condition: Condition_Input;
  note: Scalars['String']['input'];
  refundGroupId: Scalars['String']['input'];
};

export type CreateRouteLegRequest_Input = {
  arrivalPointId: Scalars['Int']['input'];
  departurePointId: Scalars['Int']['input'];
  flightId?: InputMaybe<Scalars['String']['input']>;
  routeId: Scalars['String']['input'];
};

export type CreateRouteLegResponse = {
  __typename?: 'CreateRouteLegResponse';
  routeLeg: RouteLeg;
};

export type CreateRouteLegsBatchRequest_Input = {
  routeLegs: Array<InputMaybe<BatchRouteLeg_Input>>;
};

export type CreateRouteLegsBatchResponse = {
  __typename?: 'CreateRouteLegsBatchResponse';
  routeLegs: Array<Maybe<RouteLeg>>;
};

export type CreateRouteRequest_Input = {
  arrivalId: Scalars['String']['input'];
  counterpartyId: Scalars['String']['input'];
  departureId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  isActive: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  refundGroupId: Scalars['String']['input'];
  tariffsIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type CreateRouteResponse = {
  __typename?: 'CreateRouteResponse';
  route: Route;
};

export type CreateRouteSequenceRequest_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  flightId?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  routeId: Scalars['String']['input'];
  sortOrder: Scalars['Int']['input'];
};

export type CreateRouteSequenceResponse = {
  __typename?: 'CreateRouteSequenceResponse';
  routeSequence?: Maybe<RouteSequence>;
};

export type CreateSequencePointRequest_Input = {
  distance?: InputMaybe<Scalars['Int']['input']>;
  duration: Scalars['Int']['input'];
  important: Scalars['Boolean']['input'];
  isAllowedGetOff: Scalars['Boolean']['input'];
  isAllowedSeat: Scalars['Boolean']['input'];
  pointId: Scalars['String']['input'];
  sequenceId: Scalars['String']['input'];
};

export type CreateSequencePointResponse = {
  __typename?: 'CreateSequencePointResponse';
  sequencePoint: SequencePoint;
};

export type CreateStationRequest_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  geoId: Scalars['Int']['input'];
  latitude?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  okato?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  stopType?: InputMaybe<EStopType_Input>;
};

export type CreateStationResponse = {
  __typename?: 'CreateStationResponse';
  station: Station;
};

export type CreateTariffRequest_Input = {
  currency: Scalars['String']['input'];
  description: Scalars['String']['input'];
  isDefault: Scalars['Boolean']['input'];
  isGlobal: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  percentDiscount: Scalars['Float']['input'];
  tenantId: Scalars['String']['input'];
};

export type CreateTariffResponse = {
  __typename?: 'CreateTariffResponse';
  tariff: Tariff;
};

export type CreateTenantRequest_Input = {
  address: Scalars['String']['input'];
  billingId: Scalars['String']['input'];
  counterpartyId: Scalars['String']['input'];
  country: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type CreateTenantResponse = {
  __typename?: 'CreateTenantResponse';
  tenant: Tenant;
};

export type CreateVehicleMakerRequest_Input = {
  name: Scalars['String']['input'];
};

export type CreateVehicleMakerResponse = {
  __typename?: 'CreateVehicleMakerResponse';
  vehicleMaker: VehicleMaker;
};

export type CreateVehicleModelRequest_Input = {
  makerId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateVehicleModelResponse = {
  __typename?: 'CreateVehicleModelResponse';
  vehicleModel: VehicleModel;
};

export type CreateVehicleRequest_Input = {
  amenities: Array<InputMaybe<Scalars['String']['input']>>;
  baggageCapacity: Scalars['Int']['input'];
  carrierId?: InputMaybe<Scalars['String']['input']>;
  color: Scalars['String']['input'];
  country: Scalars['String']['input'];
  isBranded: Scalars['Boolean']['input'];
  makerId: Scalars['String']['input'];
  modelId: Scalars['String']['input'];
  ownerId: Scalars['String']['input'];
  productionYear: Scalars['Int']['input'];
  regPlate: Scalars['String']['input'];
  seatCount: Scalars['Int']['input'];
  seatingSchemaId?: InputMaybe<Scalars['String']['input']>;
  vehicleType: VehicleType_Input;
  vin: Scalars['String']['input'];
};

export type CreateVehicleResponse = {
  __typename?: 'CreateVehicleResponse';
  vehicle: Vehicle;
};

export type Currency = {
  __typename?: 'Currency';
  code?: Maybe<Scalars['String']['output']>;
  exchangeRate?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  symbol: Scalars['String']['output'];
};

export type Customer = {
  __typename?: 'Customer';
  agentCustomerId: Scalars['String']['output'];
  agentId: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type DeactivateRouteRequest_Input = {
  id: Scalars['String']['input'];
};

export type DeactivateRouteResponse = {
  __typename?: 'DeactivateRouteResponse';
  route: Route;
};

export type DeleteRouteSequenceRequest_Input = {
  id: Scalars['String']['input'];
};

export type DeleteRouteSequenceResponse = {
  __typename?: 'DeleteRouteSequenceResponse';
  routeSequence?: Maybe<RouteSequence>;
};

export type DocumentType = {
  __typename?: 'DocumentType';
  code: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Driver = {
  __typename?: 'Driver';
  birthday?: Maybe<Scalars['String']['output']>;
  carrierId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  documentId?: Maybe<Scalars['Int']['output']>;
  documentNumber?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  gender: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  midName?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<Scalars['String']['output']>;
  ownerId: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type DuplicateRequest_Input = {
  departureDate: Scalars['String']['input'];
  flightId: Scalars['String']['input'];
  routeId: Scalars['String']['input'];
};

export type DuplicateResponse = {
  __typename?: 'DuplicateResponse';
  id: Scalars['String']['output'];
};

export enum EBookFields {
  BirthDate = 'birthDate',
  Citizenship = 'citizenship',
  Document = 'document',
  Email = 'email',
  Gender = 'gender',
  Name = 'name',
  Patronymic = 'patronymic',
  Phone = 'phone',
  Surname = 'surname'
}

export enum ECountdownType {
  Created = 'CREATED',
  Departure = 'DEPARTURE'
}

export enum ECountdownType_Input {
  Created = 'CREATED',
  Departure = 'DEPARTURE'
}

export enum EFlightStatus {
  Boarding = 'boarding',
  Canceled = 'canceled',
  Closed = 'closed',
  Dispatched = 'dispatched',
  Sale = 'sale',
  Suspended = 'suspended',
  Unknown = 'unknown'
}

export enum EFlightStatus_Input {
  Boarding = 'boarding',
  Canceled = 'canceled',
  Closed = 'closed',
  Dispatched = 'dispatched',
  Sale = 'sale',
  Suspended = 'suspended',
  Unknown = 'unknown'
}

export enum EFlightType {
  Charter = 'charter',
  Regular = 'regular',
  Shuttle = 'shuttle'
}

export enum EFlightType_Input {
  Charter = 'charter',
  Regular = 'regular',
  Shuttle = 'shuttle'
}

export enum EGender_Input {
  Female = 'FEMALE',
  Male = 'MALE'
}

export enum EInventoryStatus {
  Blocked = 'blocked',
  Booked = 'booked',
  Free = 'free',
  Partial = 'partial',
  Payed = 'payed',
  PayedCash = 'payedCash'
}

export enum EInventoryStatus_Input {
  Blocked = 'blocked',
  Booked = 'booked',
  Free = 'free',
  Partial = 'partial',
  Payed = 'payed',
  PayedCash = 'payedCash'
}

export enum EOrderStatus {
  Active = 'active',
  Booked = 'booked',
  Cancelled = 'cancelled',
  Confirmed = 'confirmed',
  Error = 'error',
  Expired = 'expired',
  Landing = 'landing',
  NotCome = 'not_come',
  Unknown = 'unknown',
  WaitingConfirmation = 'waiting_confirmation'
}

export enum EOrderStatus_Input {
  Active = 'active',
  Booked = 'booked',
  Cancelled = 'cancelled',
  Confirmed = 'confirmed',
  Error = 'error',
  Expired = 'expired',
  Landing = 'landing',
  NotCome = 'not_come',
  Unknown = 'unknown',
  WaitingConfirmation = 'waiting_confirmation'
}

export enum EPaymentMethod {
  AtlasPromocode = 'atlas_promocode',
  Card = 'card',
  Cash = 'cash',
  Miles = 'miles',
  Reccur = 'reccur'
}

export enum EPaymentMethod_Input {
  AtlasPromocode = 'atlas_promocode',
  Card = 'card',
  Cash = 'cash',
  Miles = 'miles',
  Reccur = 'reccur'
}

export enum EProcedureType {
  Multiply = 'MULTIPLY',
  Percent = 'PERCENT'
}

export enum EProcedureType_Input {
  Multiply = 'MULTIPLY',
  Percent = 'PERCENT'
}

export enum EStopType {
  Airport = 'AIRPORT',
  BusStation = 'BUS_STATION',
  BusStop = 'BUS_STOP',
  Other = 'OTHER',
  PublicTransportStop = 'PUBLIC_TRANSPORT_STOP',
  RailwayStation = 'RAILWAY_STATION',
  ShoppingCenter = 'SHOPPING_CENTER',
  Unknown = 'UNKNOWN'
}

export enum EStopType_Input {
  Airport = 'AIRPORT',
  BusStation = 'BUS_STATION',
  BusStop = 'BUS_STOP',
  Other = 'OTHER',
  PublicTransportStop = 'PUBLIC_TRANSPORT_STOP',
  RailwayStation = 'RAILWAY_STATION',
  ShoppingCenter = 'SHOPPING_CENTER',
  Unknown = 'UNKNOWN'
}

export enum ETargetColumn_Input {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export enum ETicketClass {
  Baggage = 'BAGGAGE',
  Passenger = 'PASSENGER'
}

export type EgisRegistryEntry = {
  __typename?: 'EgisRegistryEntry';
  id: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
  registryRegNumber: Scalars['String']['output'];
  routeId: Scalars['String']['output'];
};

export type Empty = {
  __typename?: 'Empty';
  code?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type EnableRouteSequenceRequest_Input = {
  id: Scalars['String']['input'];
};

export type EnableRouteSequenceResponse = {
  __typename?: 'EnableRouteSequenceResponse';
  routeSequence?: Maybe<RouteSequence>;
};

export type FindOrCreateCustomerRequest_Input = {
  agentCustomerId: Scalars['String']['input'];
  agentId: Scalars['String']['input'];
};

export type FindOrCreateCustomerResponse = {
  __typename?: 'FindOrCreateCustomerResponse';
  customer: Customer;
  customerFound: Scalars['Boolean']['output'];
};

export type Flight = {
  __typename?: 'Flight';
  animals?: Maybe<Scalars['String']['output']>;
  arrivalCoords?: Maybe<Coordinates2>;
  arrivalTime: Scalars['String']['output'];
  atlasMeta?: Maybe<Scalars['String']['output']>;
  benefits: Array<Maybe<Scalars['String']['output']>>;
  bookFields: Array<Maybe<EBookFields>>;
  carrier: Scalars['String']['output'];
  carrierBillingId: Scalars['Int']['output'];
  carrierId: Scalars['String']['output'];
  carrierPhones: Array<Maybe<Scalars['String']['output']>>;
  connector: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  departureCoords?: Maybe<Coordinates>;
  departureTime: Scalars['String']['output'];
  dischargeStopsIds: Array<Maybe<Scalars['String']['output']>>;
  distance?: Maybe<Scalars['Float']['output']>;
  driver1?: Maybe<Scalars['String']['output']>;
  driver2?: Maybe<Scalars['String']['output']>;
  driver3?: Maybe<Scalars['String']['output']>;
  dynamicConfig?: Maybe<Scalars['String']['output']>;
  dynamicMode?: Maybe<Scalars['Boolean']['output']>;
  egisRegistryNumber?: Maybe<Scalars['String']['output']>;
  fee: Scalars['Float']['output'];
  flightPopular?: Maybe<Scalars['Int']['output']>;
  freeSeats: Scalars['Int']['output'];
  freighter?: Maybe<Scalars['String']['output']>;
  fromId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isHidden: Scalars['Boolean']['output'];
  legal?: Maybe<Legal>;
  luggage?: Maybe<Scalars['String']['output']>;
  maxBookingDays?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  nid: Scalars['Int']['output'];
  number?: Maybe<Scalars['String']['output']>;
  onlinePrice?: Maybe<Scalars['Float']['output']>;
  onlineRefund?: Maybe<Scalars['Boolean']['output']>;
  ownerId: Scalars['String']['output'];
  partner: Scalars['String']['output'];
  partnerEmail?: Maybe<Scalars['String']['output']>;
  partnerName: Scalars['String']['output'];
  partnerPhone?: Maybe<Scalars['String']['output']>;
  paymentsType: Array<Maybe<Scalars['String']['output']>>;
  pickupStopsIds: Array<Maybe<Scalars['String']['output']>>;
  price: Scalars['Float']['output'];
  refundConditions?: Maybe<Scalars['String']['output']>;
  rideNumber?: Maybe<Scalars['Int']['output']>;
  rideType?: Maybe<EFlightType>;
  routeId: Scalars['String']['output'];
  routeName?: Maybe<Scalars['String']['output']>;
  rulesLink?: Maybe<Scalars['String']['output']>;
  saleTypes: Array<Maybe<Scalars['String']['output']>>;
  seatingRequired?: Maybe<Scalars['Boolean']['output']>;
  seatingScheme: Array<Maybe<Scalars['String']['output']>>;
  status?: Maybe<Scalars['String']['output']>;
  ticketLimit?: Maybe<Scalars['Int']['output']>;
  toId: Scalars['String']['output'];
  validBefore?: Maybe<Scalars['Int']['output']>;
  vehicleId?: Maybe<Scalars['String']['output']>;
};

export type FlightEgisSchedule = {
  __typename?: 'FlightEgisSchedule';
  createdAt: Scalars['String']['output'];
  dayOfWeek: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  egisRegistryId: Scalars['String']['output'];
  endTime: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  routeId: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type FreeInventoriesRequest_Input = {
  ids: Array<InputMaybe<Scalars['String']['input']>>;
};

export type FreeInventoriesResponse = {
  __typename?: 'FreeInventoriesResponse';
  ok: Scalars['Boolean']['output'];
};

export type Geoname = {
  __typename?: 'Geoname';
  admin1Id: Scalars['Int']['output'];
  admin1Name?: Maybe<Scalars['String']['output']>;
  alternateNames: Array<Maybe<Scalars['String']['output']>>;
  country: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  latitude: Scalars['Float']['output'];
  localizedName?: Maybe<LocalizedName>;
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  population: Scalars['Int']['output'];
  timezone: Scalars['String']['output'];
};

export type GetAllAmenitiesResponse = {
  __typename?: 'GetAllAmenitiesResponse';
  amenities: Array<Maybe<Amenity>>;
};

export type GetAllCarrierRequest_Input = {
  egisOTBId?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetAllCarrierResponse = {
  __typename?: 'GetAllCarrierResponse';
  carriers: Array<Maybe<Carrier>>;
};

export type GetAllContractorsResponse = {
  __typename?: 'GetAllContractorsResponse';
  contractors: Array<Maybe<Contractor>>;
};

export type GetAllCounterpartyRequest_Input = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetAllCounterpartyResponse = {
  __typename?: 'GetAllCounterpartyResponse';
  counterparties: Array<Maybe<Counterparty>>;
};

export type GetAllCustomersRequest_Input = {
  ids: Array<InputMaybe<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetAllCustomersResponse = {
  __typename?: 'GetAllCustomersResponse';
  customers: Array<Maybe<Customer>>;
};

export type GetAllDocumentTypeRequest_Input = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetAllDocumentTypeResponse = {
  __typename?: 'GetAllDocumentTypeResponse';
  documentTypes: Array<Maybe<DocumentType>>;
};

export type GetAllDriversRequest_Input = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetAllDriversResponse = {
  __typename?: 'GetAllDriversResponse';
  drivers: Array<Maybe<Driver>>;
};

export type GetAllFlightsRequest_Input = {
  carrierId?: InputMaybe<Scalars['String']['input']>;
  counterpartyId?: InputMaybe<Scalars['String']['input']>;
  departureEndDate?: InputMaybe<Scalars['String']['input']>;
  departureStartDate?: InputMaybe<Scalars['String']['input']>;
  driverEmail?: InputMaybe<Scalars['String']['input']>;
  driverId?: InputMaybe<Scalars['String']['input']>;
  egisRegistryNumber?: InputMaybe<Scalars['String']['input']>;
  flightIds?: InputMaybe<Scalars['String']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<EFlightStatus_Input>>>;
  vehicleId?: InputMaybe<Scalars['String']['input']>;
};

export type GetAllFlightsResponse = {
  __typename?: 'GetAllFlightsResponse';
  flights: Array<Maybe<Flight>>;
};

export type GetAllOrdersRequest_Input = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  departureDate?: InputMaybe<Scalars['String']['input']>;
  flightId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  routeId?: InputMaybe<Scalars['String']['input']>;
  status: Array<InputMaybe<EOrderStatus_Input>>;
};

export type GetAllOrdersResponse = {
  __typename?: 'GetAllOrdersResponse';
  orders: Array<Maybe<Order>>;
};

export type GetAllPassengersRequest_Input = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetAllPassengersResponse = {
  __typename?: 'GetAllPassengersResponse';
  passengers: Array<Maybe<Passenger>>;
};

export type GetAllRequest = {
  __typename?: 'GetAllRequest';
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type GetAllRouteLegsRequest_Input = {
  flightId?: InputMaybe<Scalars['String']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  routeId?: InputMaybe<Scalars['String']['input']>;
};

export type GetAllRouteLegsResponse = {
  __typename?: 'GetAllRouteLegsResponse';
  routeLegs: Array<Maybe<RouteLeg>>;
  total: Scalars['Int']['output'];
};

export type GetAllRouteSequencesRequest_Input = {
  flightId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  routeId: Scalars['String']['input'];
};

export type GetAllRouteSequencesResponse = {
  __typename?: 'GetAllRouteSequencesResponse';
  routeSequences: Array<Maybe<RouteSequence>>;
  total: Scalars['Int']['output'];
};

export type GetAllRoutesRequest_Input = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetAllRoutesResponse = {
  __typename?: 'GetAllRoutesResponse';
  routes: Array<Maybe<Route>>;
  total: Scalars['Int']['output'];
};

export type GetAllSequencePointsRequest_Input = {
  sequenceId?: InputMaybe<Scalars['String']['input']>;
  sortPointsByOrder?: InputMaybe<Scalars['Boolean']['input']>;
  stationId?: InputMaybe<Scalars['String']['input']>;
};

export type GetAllSequencePointsResponse = {
  __typename?: 'GetAllSequencePointsResponse';
  sequencePoints: Array<Maybe<SequencePoint>>;
};

export type GetAllTariffRequest_Input = {
  flightId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  routeId?: InputMaybe<Scalars['String']['input']>;
  templateId?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
};

export type GetAllTariffResponse = {
  __typename?: 'GetAllTariffResponse';
  tariffs?: Maybe<Array<Maybe<Tariff>>>;
};

export type GetAllTenantRequest_Input = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetAllTenantResponse = {
  __typename?: 'GetAllTenantResponse';
  tenants: Array<Maybe<Tenant>>;
};

export type GetAllVehicleMakersResponse = {
  __typename?: 'GetAllVehicleMakersResponse';
  vehicleMakers: Array<Maybe<VehicleMaker>>;
};

export type GetAllVehicleModelsRequest_Input = {
  makerId: Scalars['String']['input'];
};

export type GetAllVehicleModelsResponse = {
  __typename?: 'GetAllVehicleModelsResponse';
  vehicleModels: Array<Maybe<VehicleModel>>;
};

export type GetAllVehiclesResponse = {
  __typename?: 'GetAllVehiclesResponse';
  vehicles: Array<Maybe<Vehicle>>;
};

export type GetArrivalIdsRequest_Input = {
  departurePointId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetArrivalIdsResponse = {
  __typename?: 'GetArrivalIdsResponse';
  arrivalIds: Array<Maybe<Scalars['String']['output']>>;
  total: Scalars['Int']['output'];
};

export type GetBatchFlightsRequest_Input = {
  arrivalCityId?: InputMaybe<Scalars['Int']['input']>;
  dispatchCityId?: InputMaybe<Scalars['Int']['input']>;
  ids: Array<InputMaybe<Scalars['String']['input']>>;
};

export type GetBatchFlightsResponse = {
  __typename?: 'GetBatchFlightsResponse';
  flights: Array<Maybe<Flight>>;
};

export type GetByCustomerRequest_Input = {
  customerId: Scalars['String']['input'];
};

export type GetCarrierAndTenantRequest_Input = {
  userEmail: Scalars['String']['input'];
};

export type GetCarrierAndTenantResponse = {
  __typename?: 'GetCarrierAndTenantResponse';
  carriers: Array<Maybe<Scalars['String']['output']>>;
  tenants: Array<Maybe<Scalars['String']['output']>>;
};

export type GetCarrierRequest_Input = {
  id: Scalars['String']['input'];
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetCarrierResponse = {
  __typename?: 'GetCarrierResponse';
  carrier: Carrier;
};

export type GetCarriersFromTenantRequest_Input = {
  tenantId: Scalars['String']['input'];
};

export type GetCarriersFromTenantResponse = {
  __typename?: 'GetCarriersFromTenantResponse';
  carriersIds: Array<Maybe<Scalars['String']['output']>>;
};

export type GetCitiesRequest_Input = {
  cities: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type GetCitiesResponse = {
  __typename?: 'GetCitiesResponse';
  cities: Array<Maybe<Geoname>>;
};

export type GetCityByStationRequest_Input = {
  id: Scalars['String']['input'];
};

export type GetCityByStationResponse = {
  __typename?: 'GetCityByStationResponse';
  city: Geoname;
};

export type GetCityRequest_Input = {
  cityId: Scalars['Int']['input'];
};

export type GetCityResponse = {
  __typename?: 'GetCityResponse';
  city: Geoname;
};

export type GetContractorRequest_Input = {
  id: Scalars['String']['input'];
};

export type GetContractorResponse = {
  __typename?: 'GetContractorResponse';
  contractor: Contractor;
};

export type GetCounterpartyRequest_Input = {
  id: Scalars['String']['input'];
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetCounterpartyResponse = {
  __typename?: 'GetCounterpartyResponse';
  counterparty: Counterparty;
};

export type GetCurrencyRequest_Input = {
  id: Scalars['String']['input'];
};

export type GetCurrencyResponse = {
  __typename?: 'GetCurrencyResponse';
  currency: Currency;
};

export type GetCustomerByCustomerIdRequest_Input = {
  agent: Scalars['String']['input'];
  customerId: Scalars['String']['input'];
};

export type GetCustomerByCustomerIdResponse = {
  __typename?: 'GetCustomerByCustomerIdResponse';
  customer: Customer;
};

export type GetCustomerRequest_Input = {
  id: Scalars['String']['input'];
};

export type GetCustomerResponse = {
  __typename?: 'GetCustomerResponse';
  customer: Customer;
};

export type GetDocumentTypeRequest_Input = {
  id: Scalars['Int']['input'];
};

export type GetDocumentTypeResponse = {
  __typename?: 'GetDocumentTypeResponse';
  documentType: DocumentType;
};

export type GetDriverByEmailRequest_Input = {
  email: Scalars['String']['input'];
};

export type GetDriverByEmailResponse = {
  __typename?: 'GetDriverByEmailResponse';
  driver: Driver;
};

export type GetDriverRequest_Input = {
  id: Scalars['String']['input'];
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetDriverResponse = {
  __typename?: 'GetDriverResponse';
  driver: Driver;
};

export type GetEgisRegistryEntryRequest_Input = {
  id: Scalars['String']['input'];
};

export type GetEgisRegistryEntryResponse = {
  __typename?: 'GetEgisRegistryEntryResponse';
  entry: EgisRegistryEntry;
};

export type GetFlightByNidRequest_Input = {
  nid: Scalars['String']['input'];
};

export type GetFlightEgisScheduleRequest_Input = {
  id: Scalars['String']['input'];
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetFlightEgisScheduleResponse = {
  __typename?: 'GetFlightEgisScheduleResponse';
  flightEgisSchedule: FlightEgisSchedule;
};

export type GetFlightInventoryRequest_Input = {
  flightId: Scalars['String']['input'];
  legId: Scalars['String']['input'];
  status?: InputMaybe<EInventoryStatus_Input>;
};

export type GetFlightInventoryResponse = {
  __typename?: 'GetFlightInventoryResponse';
  inventories: Array<Maybe<Inventory>>;
};

export type GetFlightRequest_Input = {
  agent?: InputMaybe<Agent_Input>;
  id: Scalars['String']['input'];
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  legId?: InputMaybe<Scalars['String']['input']>;
};

export type GetFlightResponse = {
  __typename?: 'GetFlightResponse';
  bookingAvailability?: Maybe<BookingAvailability>;
  flight: Flight;
};

export type GetFlightsReportRequest_Input = {
  carrierId: Scalars['String']['input'];
  categories: Array<InputMaybe<Scalars['String']['input']>>;
  fromDate: Scalars['String']['input'];
  toDate: Scalars['String']['input'];
};

export type GetFlightsReportResponse = {
  __typename?: 'GetFlightsReportResponse';
  items: Array<Maybe<ReportItem>>;
};

export type GetFromLegRequest_Input = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  legId: Scalars['String']['input'];
};

export type GetFromLegResponse = {
  __typename?: 'GetFromLegResponse';
  legCost: LegCost;
};

export type GetFromLegsRequest_Input = {
  legIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type GetFromLegsResponse = {
  __typename?: 'GetFromLegsResponse';
  legCosts: Array<Maybe<LegCost>>;
};

export type GetFromRouteRequest_Input = {
  flightId?: InputMaybe<Scalars['String']['input']>;
  routeId?: InputMaybe<Scalars['String']['input']>;
};

export type GetFromRouteResponse = {
  __typename?: 'GetFromRouteResponse';
  routeSettings: RouteSettingsType;
};

export type GetInventoryRequest_Input = {
  id: Scalars['String']['input'];
};

export type GetInventoryResponse = {
  __typename?: 'GetInventoryResponse';
  inventory: Inventory;
};

export type GetLegByPathRequest_Input = {
  arriveStationId: Scalars['Int']['input'];
  departureStationId: Scalars['Int']['input'];
  flightId?: InputMaybe<Scalars['String']['input']>;
  routeId?: InputMaybe<Scalars['String']['input']>;
};

export type GetLegByPathResponse = {
  __typename?: 'GetLegByPathResponse';
  leg: RouteLeg;
};

export type GetLegCostRequest_Input = {
  id: Scalars['String']['input'];
};

export type GetLegCostResponse = {
  __typename?: 'GetLegCostResponse';
  legCost: LegCost;
};

export type GetListRefundGroupsRequest_Input = {
  counterpartyId?: InputMaybe<Scalars['String']['input']>;
};

export type GetListRefundGroupsResponse = {
  __typename?: 'GetListRefundGroupsResponse';
  refundGroups: Array<Maybe<RefundGroup>>;
};

export type GetOneAmenityResponse = {
  __typename?: 'GetOneAmenityResponse';
  amenity: Amenity;
};

export type GetOnePassengerResponse = {
  __typename?: 'GetOnePassengerResponse';
  passenger: Passenger;
};

export type GetOneRequest = {
  __typename?: 'GetOneRequest';
  id: Scalars['String']['output'];
  includeDeleted?: Maybe<Scalars['Boolean']['output']>;
};

export type GetOneRequest_Input = {
  id: Scalars['String']['input'];
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetOneVehicleMakerResponse = {
  __typename?: 'GetOneVehicleMakerResponse';
  vehicleMaker: VehicleMaker;
};

export type GetOneVehicleModelResponse = {
  __typename?: 'GetOneVehicleModelResponse';
  vehicleModel: VehicleModel;
};

export type GetOneVehicleResponse = {
  __typename?: 'GetOneVehicleResponse';
  vehicle: Vehicle;
};

export type GetOrCreateCounterpartyRequest_Input = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  taxId?: InputMaybe<Scalars['String']['input']>;
};

export type GetOrCreateCounterpartyResponse = {
  __typename?: 'GetOrCreateCounterpartyResponse';
  counterparty: Counterparty;
};

export type GetOrderByNidRequest_Input = {
  nid: Scalars['String']['input'];
};

export type GetOrderRequest_Input = {
  id: Scalars['String']['input'];
};

export type GetOrderResponse = {
  __typename?: 'GetOrderResponse';
  order: Order;
};

export type GetOrdersReportRequest_Input = {
  carrierId: Scalars['String']['input'];
  categories: Array<InputMaybe<Scalars['String']['input']>>;
  fromDate: Scalars['String']['input'];
  toDate: Scalars['String']['input'];
};

export type GetOrdersReportResponse = {
  __typename?: 'GetOrdersReportResponse';
  items: Array<Maybe<ReportItem>>;
};

export type GetPassengersByStationsItem = {
  __typename?: 'GetPassengersByStationsItem';
  city: Scalars['String']['output'];
  getOff: Array<Maybe<OrderWithTickets>>;
  seat: Array<Maybe<OrderWithTickets>>;
  station: Scalars['String']['output'];
};

export type GetPassengersByStationsRequest_Input = {
  flightId: Scalars['String']['input'];
  statuses: Array<InputMaybe<EOrderStatus_Input>>;
};

export type GetPassengersByStationsResponse = {
  __typename?: 'GetPassengersByStationsResponse';
  items: Array<Maybe<GetPassengersByStationsItem>>;
};

export type GetPassengersFromFlightItem = {
  __typename?: 'GetPassengersFromFlightItem';
  legId: Scalars['String']['output'];
  order: Order;
  tickets: Array<Maybe<PassengerWithTicket>>;
};

export type GetPassengersFromFlightRequest_Input = {
  flightId: Scalars['String']['input'];
  statuses: Array<InputMaybe<EOrderStatus_Input>>;
};

export type GetPassengersFromFlightResponse = {
  __typename?: 'GetPassengersFromFlightResponse';
  items: Array<Maybe<GetPassengersFromFlightItem>>;
};

export type GetRefundGroupRequest_Input = {
  id: Scalars['String']['input'];
};

export type GetRefundRuleRequest_Input = {
  id: Scalars['String']['input'];
};

export type GetRouteLegRequest_Input = {
  id: Scalars['String']['input'];
};

export type GetRouteLegResponse = {
  __typename?: 'GetRouteLegResponse';
  routeLeg: RouteLeg;
};

export type GetRouteRefundRulesRequest_Input = {
  routeId: Scalars['String']['input'];
};

export type GetRouteRefundRulesResponse = {
  __typename?: 'GetRouteRefundRulesResponse';
  routeRefundRules: Array<Maybe<RouteRefundRule>>;
};

export type GetRouteRequest_Input = {
  id: Scalars['String']['input'];
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetRouteResponse = {
  __typename?: 'GetRouteResponse';
  route: Route;
};

export type GetRouteSettingsRequest_Input = {
  id: Scalars['String']['input'];
};

export type GetRouteSettingsResponse = {
  __typename?: 'GetRouteSettingsResponse';
  routeSettings: RouteSettingsType;
};

export type GetRouteTariffsRequest_Input = {
  routeId: Scalars['String']['input'];
};

export type GetRouteTariffsResponse = {
  __typename?: 'GetRouteTariffsResponse';
  rules?: Maybe<Array<Maybe<RouteRefundRule>>>;
};

export type GetSequencePointRequest_Input = {
  id: Scalars['String']['input'];
};

export type GetSequencePointResponse = {
  __typename?: 'GetSequencePointResponse';
  sequencePoint: SequencePoint;
};

export type GetSequenceRequest_Input = {
  id: Scalars['String']['input'];
};

export type GetSequenceResponse = {
  __typename?: 'GetSequenceResponse';
  sequence?: Maybe<RouteSequence>;
};

export type GetStationRequest_Input = {
  id: Scalars['String']['input'];
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetStationResponse = {
  __typename?: 'GetStationResponse';
  station: Station;
};

export type GetStationsByCityRequest_Input = {
  cityId: Scalars['Int']['input'];
};

export type GetStationsByCityResponse = {
  __typename?: 'GetStationsByCityResponse';
  stations: Array<Maybe<Station>>;
};

export type GetStationsByIdsRequest_Input = {
  ids: Array<InputMaybe<Scalars['String']['input']>>;
};

export type GetStationsByIdsResponse = {
  __typename?: 'GetStationsByIdsResponse';
  stations: Array<Maybe<Station>>;
};

export type GetStationsRequest_Input = {
  country?: InputMaybe<Scalars['String']['input']>;
  geoId?: InputMaybe<Scalars['Int']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  okato?: InputMaybe<Scalars['String']['input']>;
};

export type GetStationsResponse = {
  __typename?: 'GetStationsResponse';
  stations: Array<Maybe<Station>>;
};

export type GetTariffRequest_Input = {
  id: Scalars['String']['input'];
};

export type GetTariffResponse = {
  __typename?: 'GetTariffResponse';
  tariff: Tariff;
};

export type GetTenantRequest_Input = {
  id: Scalars['String']['input'];
};

export type GetTenantResponse = {
  __typename?: 'GetTenantResponse';
  tenant: Tenant;
};

export type GetTenantsFromCarrierRequest_Input = {
  carrierId: Scalars['String']['input'];
};

export type GetTenantsFromCarrierResponse = {
  __typename?: 'GetTenantsFromCarrierResponse';
  tenantsIds: Array<Maybe<Scalars['String']['output']>>;
};

export type GetTicketRequest_Input = {
  externalTicketId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type GetTicketResponse = {
  __typename?: 'GetTicketResponse';
  ticket: Ticket;
};

export type GetTicketsFromFlightRequest_Input = {
  flightId: Scalars['String']['input'];
};

export type GetTicketsFromFlightResponse = {
  __typename?: 'GetTicketsFromFlightResponse';
  tickets: Array<Maybe<Ticket>>;
};

export type GetTicketsTemplatesResponse = {
  __typename?: 'GetTicketsTemplatesResponse';
  templates: Array<Maybe<Scalars['String']['output']>>;
};

export type HasActiveFlightsRequest_Input = {
  tariffId?: InputMaybe<Scalars['String']['input']>;
};

export type HasActiveFlightsResponse = {
  __typename?: 'HasActiveFlightsResponse';
  isHas: Scalars['Boolean']['output'];
};

export type Inventory = {
  __typename?: 'Inventory';
  flightId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  legId: Scalars['String']['output'];
  nid: Scalars['String']['output'];
  seatNumber: Scalars['Int']['output'];
  status: EInventoryStatus;
};

export type LegCost = {
  __typename?: 'LegCost';
  cost?: Maybe<Scalars['Float']['output']>;
  currencyId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  legId: Scalars['String']['output'];
};

export type Legal = {
  __typename?: 'Legal';
  address?: Maybe<Scalars['String']['output']>;
  registrationAuthority?: Maybe<Scalars['String']['output']>;
  registrationDate?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
};

export type ListCurrenciesRequest_Input = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ListCurrenciesResponse = {
  __typename?: 'ListCurrenciesResponse';
  currencies: Array<Maybe<Currency>>;
  total: Scalars['Int']['output'];
};

export type ListEgisRegistryEntriesRequest_Input = {
  routeId: Scalars['String']['input'];
};

export type ListEgisRegistryEntriesResponse = {
  __typename?: 'ListEgisRegistryEntriesResponse';
  entries: Array<Maybe<EgisRegistryEntry>>;
};

export type ListFlightEgisScheduleRequest_Input = {
  dayOfWeek?: InputMaybe<Scalars['Int']['input']>;
  egisRegistryId?: InputMaybe<Scalars['String']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  routeId?: InputMaybe<Scalars['String']['input']>;
};

export type ListFlightEgisScheduleResponse = {
  __typename?: 'ListFlightEgisScheduleResponse';
  weekSchedule?: Maybe<Array<Maybe<ListFlightEgisScheduleResponse_DaySchedules>>>;
};

export type ListFlightEgisScheduleResponse_DaySchedules = {
  __typename?: 'ListFlightEgisScheduleResponse_DaySchedules';
  dayOfWeek?: Maybe<Scalars['Int']['output']>;
  schedules?: Maybe<Array<Maybe<FlightEgisSchedule>>>;
};

export type ListRefundRulesRequest_Input = {
  refundGroupId?: InputMaybe<Scalars['String']['input']>;
};

export type ListRefundRulesResponse = {
  __typename?: 'ListRefundRulesResponse';
  rules: Array<Maybe<RefundRule>>;
};

export type ListTicketsRequest_Input = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  fromDate?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  passengerId?: InputMaybe<Scalars['String']['input']>;
  targetColumnForDateSearch?: InputMaybe<ETargetColumn_Input>;
  toDate?: InputMaybe<Scalars['String']['input']>;
};

export type ListTicketsResponse = {
  __typename?: 'ListTicketsResponse';
  tickets: Array<Maybe<Ticket>>;
};

export type LocalizedName = {
  __typename?: 'LocalizedName';
  be?: Maybe<Scalars['String']['output']>;
  en?: Maybe<Scalars['String']['output']>;
  pl?: Maybe<Scalars['String']['output']>;
  ru?: Maybe<Scalars['String']['output']>;
};

export enum MessageCategory_Input {
  CancelFlight = 'cancelFlight',
  NewBooking = 'newBooking',
  NewOrder = 'newOrder',
  NewPayment = 'newPayment',
  Notification = 'notification'
}

export enum MessagePriority_Input {
  High = 'high',
  Low = 'low',
  Realtime = 'realtime'
}

export type MixedPayments = {
  __typename?: 'MixedPayments';
  amount: Scalars['Float']['output'];
  method: EPaymentMethod;
};

export type Mutation = {
  __typename?: 'Mutation';
  amenities_create?: Maybe<CreateAmenityResponse>;
  amenities_remove?: Maybe<RemoveResponse>;
  amenities_update?: Maybe<UpdateAmenityResponse>;
  carriers_create?: Maybe<CreateCarrierResponse>;
  carriers_remove?: Maybe<RemoveCarrierResponse>;
  carriers_update?: Maybe<UpdateCarrierResponse>;
  contractors_create?: Maybe<CreateContractorResponse>;
  contractors_remove?: Maybe<RemoveContractorResponse>;
  contractors_update?: Maybe<UpdateContractorResponse>;
  costs_calculate?: Maybe<CalculateCostResponse>;
  counterparties_create?: Maybe<CreateCounterpartyResponse>;
  counterparties_remove?: Maybe<RemoveCounterpartyResponse>;
  counterparties_update?: Maybe<UpdateCounterpartyResponse>;
  currencies_create?: Maybe<CreateCurrencyResponse>;
  currencies_update?: Maybe<UpdateCurrencyResponse>;
  customer_create?: Maybe<CreateCustomerResponse>;
  customer_findOrCreate?: Maybe<FindOrCreateCustomerResponse>;
  documentTypes_create?: Maybe<CreateDocumentTypeResponse>;
  documentTypes_remove?: Maybe<RemoveDocumentTypeResponse>;
  documentTypes_update?: Maybe<UpdateDocumentTypeResponse>;
  drivers_create?: Maybe<CreateDriverResponse>;
  drivers_remove?: Maybe<RemoveDriverResponse>;
  drivers_update?: Maybe<UpdateDriverResponse>;
  flightEgisScheduler_create?: Maybe<CreateFlightEgisScheduleResponse>;
  flightEgisScheduler_remove?: Maybe<RemoveFlightEgisScheduleResponse>;
  flightEgisScheduler_update?: Maybe<UpdateFlightEgisScheduleResponse>;
  flights_cancel?: Maybe<CancelFlightResponse>;
  flights_changeVisibility?: Maybe<ChangeFlightVisibilityResponse>;
  flights_create?: Maybe<CreateFlightResponse>;
  flights_createBatchAsync?: Maybe<CreateFlightBatchAsyncResponse>;
  flights_hasActiveFlights?: Maybe<HasActiveFlightsResponse>;
  flights_remove?: Maybe<RemoveFlightResponse>;
  flights_search?: Maybe<SearchFlightsResponse>;
  flights_update?: Maybe<UpdateFlightResponse>;
  geobase_suggest?: Maybe<SuggestResponse>;
  /** rpc Remove() return (); // pub-sub delete inventory for flight when events 'flight-deleted' */
  inventories_changeStatus?: Maybe<ChangeInventoryStatusResponse>;
  inventories_createInventoryForFlight?: Maybe<CreateInventoryForFlightResponse>;
  inventories_freeInventories?: Maybe<FreeInventoriesResponse>;
  legCosts_create?: Maybe<CreateLegCostResponse>;
  legCosts_update?: Maybe<UpdateLegsCostsResponse>;
  orders_bookOrder?: Maybe<BookOrderResponse>;
  orders_confirm?: Maybe<ConfirmOrderResponse>;
  passengers_create?: Maybe<CreatePassengerResponse>;
  passengers_remove?: Maybe<RemoveResponse>;
  passengers_removeByCustomer?: Maybe<RemoveResponse>;
  passengers_update?: Maybe<UpdatePassengerResponse>;
  refundGroups_create?: Maybe<CreateRefundGroupResponse>;
  refundGroups_remove?: Maybe<RemoveRefundGroupResponse>;
  refund_create?: Maybe<RefundRuleResponse>;
  refund_remove?: Maybe<RefundRuleResponse>;
  refund_update?: Maybe<RefundRuleResponse>;
  routeEgisRegistry_create?: Maybe<CreateEgisRegistryEntryResponse>;
  routeEgisRegistry_remove?: Maybe<RemoveEgisRegistryEntryResponse>;
  routeLegs_create?: Maybe<CreateRouteLegResponse>;
  routeLegs_createBatch?: Maybe<CreateRouteLegsBatchResponse>;
  routeLegs_remove?: Maybe<RemoveRouteLegResponse>;
  routeLegs_removeBatch?: Maybe<RemoveRouteLegsBatchResponse>;
  routeLegs_search?: Maybe<SearchRoutesLegsResponse>;
  routeRefundRules_addRouteTariff?: Maybe<AddRouteTariffResponse>;
  routeRefundRules_change?: Maybe<AddRouteRefundGroupResponse>;
  routeRefundRules_create?: Maybe<AddRouteRefundGroupResponse>;
  routeRefundRules_remove?: Maybe<RemoveRouteRefundGroupResponse>;
  routeRefundRules_removeRouteTariff?: Maybe<RemoveRouteTariffResponse>;
  routeSequences_createRouteSequence?: Maybe<CreateRouteSequenceResponse>;
  routeSequences_deleteRouteSequence?: Maybe<DeleteRouteSequenceResponse>;
  routeSequences_enableRouteSequence?: Maybe<EnableRouteSequenceResponse>;
  routeSequences_searchRouteSequences?: Maybe<SearchRouteSequencesResponse>;
  routeSequences_updateRouteSequence?: Maybe<UpdateRouteSequenceResponse>;
  routeSettings_updateRouteSettings?: Maybe<UpdateRouteSettingsResponse>;
  routes_activate?: Maybe<ActivateRouteResponse>;
  routes_batchCreate?: Maybe<BatchCreateRoutesResponse>;
  routes_batchRemove?: Maybe<BatchRemoveRoutesResponse>;
  routes_batchUpdate?: Maybe<BatchUpdateRoutesResponse>;
  routes_create?: Maybe<CreateRouteResponse>;
  routes_deactivate?: Maybe<DeactivateRouteResponse>;
  routes_duplicate?: Maybe<DuplicateResponse>;
  routes_remove?: Maybe<RemoveRouteResponse>;
  routes_removeFlightReferences?: Maybe<RemoveFlightReferencesResponse>;
  routes_search?: Maybe<SearchRoutesResponse>;
  routes_update?: Maybe<UpdateRouteResponse>;
  sender_sendSmsMessage?: Maybe<SendSmsResponse>;
  sender_sendSmsToFlightPassengers?: Maybe<SendSmsToFlightPassengersResponse>;
  sequencePoints_create?: Maybe<CreateSequencePointResponse>;
  sequencePoints_remove?: Maybe<RemoveSequencePointResponse>;
  sequencePoints_update?: Maybe<UpdateSequencePointResponse>;
  sequencePoints_updatePosition?: Maybe<UpdatePositionResponse>;
  stations_createStation?: Maybe<CreateStationResponse>;
  stations_removeStation?: Maybe<RemoveStationResponse>;
  stations_updateStation?: Maybe<UpdateStationResponse>;
  tariffs_create?: Maybe<CreateTariffResponse>;
  tariffs_remove?: Maybe<RemoveTariffResponse>;
  tariffs_update?: Maybe<UpdateTariffResponse>;
  tenantsCarriers_addTenantCarrier?: Maybe<AddTenantCarrierResponse>;
  tenantsCarriers_removeTenantCarrier?: Maybe<RemoveTenantCarrierResponse>;
  tenantsRoutes_addTenantRoutes?: Maybe<AddTenantRoutesResponse>;
  tenantsRoutes_checkAccess?: Maybe<CheckAccessResponse>;
  tenantsRoutes_removeTenantRoutes?: Maybe<RemoveTenantRoutesResponse>;
  tenants_create?: Maybe<CreateTenantResponse>;
  tenants_remove?: Maybe<RemoveTenantResponse>;
  tenants_update?: Maybe<UpdateTenantResponse>;
  tickets_calcTicketReturn?: Maybe<CalcTicketReturnResponse>;
  tickets_cancel?: Maybe<CancelTicketResponse>;
  tickets_changeStatusByDriver?: Maybe<ChangeStatusByDriverResponse>;
  tickets_returnTicket?: Maybe<ReturnTicketResponse>;
  tickets_ticketReport?: Maybe<TicketReportResponse>;
  users_addCarrierToUser?: Maybe<AddCarrierToUserResponse>;
  users_addTenantToUser?: Maybe<AddTenantToUserResponse>;
  users_removeCarrierFromUser?: Maybe<RemoveCarrierFromUserResponse>;
  users_removeTenantFromUser?: Maybe<RemoveTenantFromUserResponse>;
  /** required */
  vehicleMakers_create?: Maybe<CreateVehicleMakerResponse>;
  /** required */
  vehicleMakers_remove?: Maybe<RemoveResponse>;
  /** required */
  vehicleMakers_update?: Maybe<UpdateVehicleMakerResponse>;
  vehicleModels_create?: Maybe<CreateVehicleModelResponse>;
  vehicleModels_remove?: Maybe<RemoveResponse>;
  vehicleModels_update?: Maybe<UpdateVehicleModelResponse>;
  vehicles_addAmenity?: Maybe<AddAmenityResponse>;
  vehicles_create?: Maybe<CreateVehicleResponse>;
  vehicles_remove?: Maybe<RemoveResponse>;
  vehicles_removeAmenity?: Maybe<RemoveAmenityResponse>;
  vehicles_update?: Maybe<UpdateVehicleResponse>;
};


export type MutationAmenities_CreateArgs = {
  req?: InputMaybe<CreateAmenityRequest_Input>;
};


export type MutationAmenities_RemoveArgs = {
  req?: InputMaybe<RemoveRequest_Input>;
};


export type MutationAmenities_UpdateArgs = {
  req?: InputMaybe<UpdateAmenityRequest_Input>;
};


export type MutationCarriers_CreateArgs = {
  req?: InputMaybe<CreateCarrierRequest_Input>;
};


export type MutationCarriers_RemoveArgs = {
  req?: InputMaybe<RemoveCarrierRequest_Input>;
};


export type MutationCarriers_UpdateArgs = {
  req?: InputMaybe<UpdateCarrierRequest_Input>;
};


export type MutationContractors_CreateArgs = {
  req?: InputMaybe<CreateContractorRequest_Input>;
};


export type MutationContractors_RemoveArgs = {
  req?: InputMaybe<RemoveContractorRequest_Input>;
};


export type MutationContractors_UpdateArgs = {
  req?: InputMaybe<UpdateContractorRequest_Input>;
};


export type MutationCosts_CalculateArgs = {
  req?: InputMaybe<CalculateCostRequest_Input>;
};


export type MutationCounterparties_CreateArgs = {
  req?: InputMaybe<CreateCounterpartyRequest_Input>;
};


export type MutationCounterparties_RemoveArgs = {
  req?: InputMaybe<RemoveCounterpartyRequest_Input>;
};


export type MutationCounterparties_UpdateArgs = {
  req?: InputMaybe<UpdateCounterpartyRequest_Input>;
};


export type MutationCurrencies_CreateArgs = {
  req?: InputMaybe<CreateCurrencyRequest_Input>;
};


export type MutationCurrencies_UpdateArgs = {
  req?: InputMaybe<UpdateCurrencyRequest_Input>;
};


export type MutationCustomer_CreateArgs = {
  req?: InputMaybe<CreateCustomerRequest_Input>;
};


export type MutationCustomer_FindOrCreateArgs = {
  req?: InputMaybe<FindOrCreateCustomerRequest_Input>;
};


export type MutationDocumentTypes_CreateArgs = {
  req?: InputMaybe<CreateDocumentTypeRequest_Input>;
};


export type MutationDocumentTypes_RemoveArgs = {
  req?: InputMaybe<RemoveDocumentTypeRequest_Input>;
};


export type MutationDocumentTypes_UpdateArgs = {
  req?: InputMaybe<UpdateDocumentTypeRequest_Input>;
};


export type MutationDrivers_CreateArgs = {
  req?: InputMaybe<CreateDriverRequest_Input>;
};


export type MutationDrivers_RemoveArgs = {
  req?: InputMaybe<RemoveDriverRequest_Input>;
};


export type MutationDrivers_UpdateArgs = {
  req?: InputMaybe<UpdateDriverRequest_Input>;
};


export type MutationFlightEgisScheduler_CreateArgs = {
  req?: InputMaybe<CreateFlightEgisScheduleRequest_Input>;
};


export type MutationFlightEgisScheduler_RemoveArgs = {
  req?: InputMaybe<RemoveFlightEgisScheduleRequest_Input>;
};


export type MutationFlightEgisScheduler_UpdateArgs = {
  req?: InputMaybe<UpdateFlightEgisScheduleRequest_Input>;
};


export type MutationFlights_CancelArgs = {
  req?: InputMaybe<CancelFlightRequest_Input>;
};


export type MutationFlights_ChangeVisibilityArgs = {
  req?: InputMaybe<ChangeFlightVisibilityRequest_Input>;
};


export type MutationFlights_CreateArgs = {
  req?: InputMaybe<CreateFlightRequest_Input>;
};


export type MutationFlights_CreateBatchAsyncArgs = {
  req?: InputMaybe<CreateFlightBatchAsyncRequest_Input>;
};


export type MutationFlights_HasActiveFlightsArgs = {
  req?: InputMaybe<HasActiveFlightsRequest_Input>;
};


export type MutationFlights_RemoveArgs = {
  req?: InputMaybe<RemoveFlightRequest_Input>;
};


export type MutationFlights_SearchArgs = {
  req?: InputMaybe<SearchFlightsRequest_Input>;
};


export type MutationFlights_UpdateArgs = {
  req?: InputMaybe<UpdateFlightRequest_Input>;
};


export type MutationGeobase_SuggestArgs = {
  req?: InputMaybe<SuggestRequest_Input>;
};


export type MutationInventories_ChangeStatusArgs = {
  req?: InputMaybe<ChangeInventoryStatusRequest_Input>;
};


export type MutationInventories_CreateInventoryForFlightArgs = {
  req?: InputMaybe<CreateInventorForFlightRequest_Input>;
};


export type MutationInventories_FreeInventoriesArgs = {
  req?: InputMaybe<FreeInventoriesRequest_Input>;
};


export type MutationLegCosts_CreateArgs = {
  req?: InputMaybe<CreateLegCostRequest_Input>;
};


export type MutationLegCosts_UpdateArgs = {
  req?: InputMaybe<UpdateLegsCostsRequest_Input>;
};


export type MutationOrders_BookOrderArgs = {
  req?: InputMaybe<BookOrderRequest_Input>;
};


export type MutationOrders_ConfirmArgs = {
  req?: InputMaybe<ConfirmOrderRequest_Input>;
};


export type MutationPassengers_CreateArgs = {
  req?: InputMaybe<CreatePassengerRequest_Input>;
};


export type MutationPassengers_RemoveArgs = {
  req?: InputMaybe<RemoveRequest_Input>;
};


export type MutationPassengers_RemoveByCustomerArgs = {
  req?: InputMaybe<RemoveByCustomerRequest_Input>;
};


export type MutationPassengers_UpdateArgs = {
  req?: InputMaybe<UpdatePassengerRequest_Input>;
};


export type MutationRefundGroups_CreateArgs = {
  req?: InputMaybe<CreateRefundGroupRequest_Input>;
};


export type MutationRefundGroups_RemoveArgs = {
  req?: InputMaybe<RemoveRefundGroupRequest_Input>;
};


export type MutationRefund_CreateArgs = {
  req?: InputMaybe<CreateRefundRuleRequest_Input>;
};


export type MutationRefund_RemoveArgs = {
  req?: InputMaybe<RemoveRefundRuleRequest_Input>;
};


export type MutationRefund_UpdateArgs = {
  req?: InputMaybe<UpdateRefundRuleRequest_Input>;
};


export type MutationRouteEgisRegistry_CreateArgs = {
  req?: InputMaybe<CreateEgisRegistryEntryRequest_Input>;
};


export type MutationRouteEgisRegistry_RemoveArgs = {
  req?: InputMaybe<RemoveEgisRegistryEntryRequest_Input>;
};


export type MutationRouteLegs_CreateArgs = {
  req?: InputMaybe<CreateRouteLegRequest_Input>;
};


export type MutationRouteLegs_CreateBatchArgs = {
  req?: InputMaybe<CreateRouteLegsBatchRequest_Input>;
};


export type MutationRouteLegs_RemoveArgs = {
  req?: InputMaybe<RemoveRouteLegRequest_Input>;
};


export type MutationRouteLegs_RemoveBatchArgs = {
  req?: InputMaybe<RemoveRouteLegsBatchRequest_Input>;
};


export type MutationRouteLegs_SearchArgs = {
  req?: InputMaybe<SearchRoutesLegsRequest_Input>;
};


export type MutationRouteRefundRules_AddRouteTariffArgs = {
  req?: InputMaybe<AddRouteTariffRequest_Input>;
};


export type MutationRouteRefundRules_ChangeArgs = {
  req?: InputMaybe<ChangeRouteRefundRuleRequest_Input>;
};


export type MutationRouteRefundRules_CreateArgs = {
  req?: InputMaybe<AddRouteRefundGroupRequest_Input>;
};


export type MutationRouteRefundRules_RemoveArgs = {
  req?: InputMaybe<RemoveRouteRefundGroupRequest_Input>;
};


export type MutationRouteRefundRules_RemoveRouteTariffArgs = {
  req?: InputMaybe<RemoveRouteTariffRequest_Input>;
};


export type MutationRouteSequences_CreateRouteSequenceArgs = {
  req?: InputMaybe<CreateRouteSequenceRequest_Input>;
};


export type MutationRouteSequences_DeleteRouteSequenceArgs = {
  req?: InputMaybe<DeleteRouteSequenceRequest_Input>;
};


export type MutationRouteSequences_EnableRouteSequenceArgs = {
  req?: InputMaybe<EnableRouteSequenceRequest_Input>;
};


export type MutationRouteSequences_SearchRouteSequencesArgs = {
  req?: InputMaybe<SearchRouteSequencesRequest_Input>;
};


export type MutationRouteSequences_UpdateRouteSequenceArgs = {
  req?: InputMaybe<UpdateRouteSequenceRequest_Input>;
};


export type MutationRouteSettings_UpdateRouteSettingsArgs = {
  req?: InputMaybe<UpdateRouteSettingsRequest_Input>;
};


export type MutationRoutes_ActivateArgs = {
  req?: InputMaybe<ActivateRouteRequest_Input>;
};


export type MutationRoutes_BatchCreateArgs = {
  req?: InputMaybe<BatchCreateRoutesRequest_Input>;
};


export type MutationRoutes_BatchRemoveArgs = {
  req?: InputMaybe<BatchRemoveRoutesRequest_Input>;
};


export type MutationRoutes_BatchUpdateArgs = {
  req?: InputMaybe<BatchUpdateRoutesRequest_Input>;
};


export type MutationRoutes_CreateArgs = {
  req?: InputMaybe<CreateRouteRequest_Input>;
};


export type MutationRoutes_DeactivateArgs = {
  req?: InputMaybe<DeactivateRouteRequest_Input>;
};


export type MutationRoutes_DuplicateArgs = {
  req?: InputMaybe<DuplicateRequest_Input>;
};


export type MutationRoutes_RemoveArgs = {
  req?: InputMaybe<RemoveRouteRequest_Input>;
};


export type MutationRoutes_RemoveFlightReferencesArgs = {
  req?: InputMaybe<RemoveFlightReferencesRequest_Input>;
};


export type MutationRoutes_SearchArgs = {
  req?: InputMaybe<SearchRoutesRequest_Input>;
};


export type MutationRoutes_UpdateArgs = {
  req?: InputMaybe<UpdateRouteRequest_Input>;
};


export type MutationSender_SendSmsMessageArgs = {
  req?: InputMaybe<SendSmsRequest_Input>;
};


export type MutationSender_SendSmsToFlightPassengersArgs = {
  req?: InputMaybe<SendSmsToFlightPassengersRequest_Input>;
};


export type MutationSequencePoints_CreateArgs = {
  req?: InputMaybe<CreateSequencePointRequest_Input>;
};


export type MutationSequencePoints_RemoveArgs = {
  req?: InputMaybe<RemoveSequencePointRequest_Input>;
};


export type MutationSequencePoints_UpdateArgs = {
  req?: InputMaybe<UpdateSequencePointRequest_Input>;
};


export type MutationSequencePoints_UpdatePositionArgs = {
  req?: InputMaybe<UpdatePositionRequest_Input>;
};


export type MutationStations_CreateStationArgs = {
  req?: InputMaybe<CreateStationRequest_Input>;
};


export type MutationStations_RemoveStationArgs = {
  req?: InputMaybe<RemoveStationRequest_Input>;
};


export type MutationStations_UpdateStationArgs = {
  req?: InputMaybe<UpdateStationRequest_Input>;
};


export type MutationTariffs_CreateArgs = {
  req?: InputMaybe<CreateTariffRequest_Input>;
};


export type MutationTariffs_RemoveArgs = {
  req?: InputMaybe<RemoveTariffRequest_Input>;
};


export type MutationTariffs_UpdateArgs = {
  req?: InputMaybe<UpdateTariffRequest_Input>;
};


export type MutationTenantsCarriers_AddTenantCarrierArgs = {
  req?: InputMaybe<AddTenantCarrierRequest_Input>;
};


export type MutationTenantsCarriers_RemoveTenantCarrierArgs = {
  req?: InputMaybe<RemoveTenantCarrierRequest_Input>;
};


export type MutationTenantsRoutes_AddTenantRoutesArgs = {
  req?: InputMaybe<AddTenantRoutesRequest_Input>;
};


export type MutationTenantsRoutes_CheckAccessArgs = {
  req?: InputMaybe<CheckAccessRequest_Input>;
};


export type MutationTenantsRoutes_RemoveTenantRoutesArgs = {
  req?: InputMaybe<RemoveTenantRoutesRequest_Input>;
};


export type MutationTenants_CreateArgs = {
  req?: InputMaybe<CreateTenantRequest_Input>;
};


export type MutationTenants_RemoveArgs = {
  req?: InputMaybe<RemoveTenantRequest_Input>;
};


export type MutationTenants_UpdateArgs = {
  req?: InputMaybe<UpdateTenantRequest_Input>;
};


export type MutationTickets_CalcTicketReturnArgs = {
  req?: InputMaybe<CalcTicketReturnRequest_Input>;
};


export type MutationTickets_CancelArgs = {
  req?: InputMaybe<CancelTicketRequest_Input>;
};


export type MutationTickets_ChangeStatusByDriverArgs = {
  req?: InputMaybe<ChangeStatusByDriverRequest_Input>;
};


export type MutationTickets_ReturnTicketArgs = {
  req?: InputMaybe<ReturnTicketRequest_Input>;
};


export type MutationTickets_TicketReportArgs = {
  req?: InputMaybe<TicketReportRequest_Input>;
};


export type MutationUsers_AddCarrierToUserArgs = {
  req?: InputMaybe<AddCarrierToUserRequest_Input>;
};


export type MutationUsers_AddTenantToUserArgs = {
  req?: InputMaybe<AddTenantToUserRequest_Input>;
};


export type MutationUsers_RemoveCarrierFromUserArgs = {
  req?: InputMaybe<RemoveCarrierFromUserRequest_Input>;
};


export type MutationUsers_RemoveTenantFromUserArgs = {
  req?: InputMaybe<RemoveTenantFromUserRequest_Input>;
};


export type MutationVehicleMakers_CreateArgs = {
  req?: InputMaybe<CreateVehicleMakerRequest_Input>;
};


export type MutationVehicleMakers_RemoveArgs = {
  req?: InputMaybe<RemoveRequest_Input>;
};


export type MutationVehicleMakers_UpdateArgs = {
  req?: InputMaybe<UpdateVehicleMakerRequest_Input>;
};


export type MutationVehicleModels_CreateArgs = {
  req?: InputMaybe<CreateVehicleModelRequest_Input>;
};


export type MutationVehicleModels_RemoveArgs = {
  req?: InputMaybe<RemoveRequest_Input>;
};


export type MutationVehicleModels_UpdateArgs = {
  req?: InputMaybe<UpdateVehicleModelRequest_Input>;
};


export type MutationVehicles_AddAmenityArgs = {
  req?: InputMaybe<AddAmenityRequest_Input>;
};


export type MutationVehicles_CreateArgs = {
  req?: InputMaybe<CreateVehicleRequest_Input>;
};


export type MutationVehicles_RemoveArgs = {
  req?: InputMaybe<RemoveRequest_Input>;
};


export type MutationVehicles_RemoveAmenityArgs = {
  req?: InputMaybe<RemoveAmenityRequest_Input>;
};


export type MutationVehicles_UpdateArgs = {
  req?: InputMaybe<UpdateVehicleRequest_Input>;
};

export type Order = {
  __typename?: 'Order';
  arrivalDate: Scalars['String']['output'];
  arrivalStop: Scalars['String']['output'];
  basePrice: Scalars['Float']['output'];
  cancelledAt?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  currencyId: Scalars['String']['output'];
  customerId?: Maybe<Scalars['String']['output']>;
  departureDate: Scalars['String']['output'];
  departureStop: Scalars['String']['output'];
  expirationMinutes: Scalars['Int']['output'];
  finalPrice: Scalars['Float']['output'];
  flightId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isArrived: Scalars['Boolean']['output'];
  mixedPayments?: Maybe<MixedPayments>;
  nid: Scalars['String']['output'];
  onlinePrice: Scalars['Float']['output'];
  paymentMethod: EPaymentMethod;
  promocode?: Maybe<Scalars['String']['output']>;
  status: EOrderStatus;
};

export type OrderWithTickets = {
  __typename?: 'OrderWithTickets';
  currencySymbol: Scalars['String']['output'];
  order: Order;
  tickets: Array<Maybe<PassengerWithTicket>>;
};

export type Passenger = {
  __typename?: 'Passenger';
  birthdayYear?: Maybe<Scalars['Int']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  customerId: Scalars['String']['output'];
  documentNumber?: Maybe<Scalars['String']['output']>;
  documentTypeId?: Maybe<Scalars['Int']['output']>;
  firstName: Scalars['String']['output'];
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  nid: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type PassengerPayload_Input = {
  birthday?: InputMaybe<Scalars['String']['input']>;
  citizenshipISO2?: InputMaybe<Scalars['String']['input']>;
  docNum?: InputMaybe<Scalars['String']['input']>;
  docSeries?: InputMaybe<Scalars['String']['input']>;
  docTypeId?: InputMaybe<Scalars['Int']['input']>;
  firstName: Scalars['String']['input'];
  gender: EGender_Input;
  info?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type PassengerWithTicket = {
  __typename?: 'PassengerWithTicket';
  passenger: Passenger;
  ticket: Ticket;
};

export type PositionedPoint_Input = {
  id: Scalars['String']['input'];
  positionOrder: Scalars['Int']['input'];
};

export type Query = {
  __typename?: 'Query';
  amenities_getAll?: Maybe<GetAllAmenitiesResponse>;
  amenities_getOne?: Maybe<GetOneAmenityResponse>;
  carriers_get?: Maybe<GetCarrierResponse>;
  carriers_getAll?: Maybe<GetAllCarrierResponse>;
  contractors_get?: Maybe<GetContractorResponse>;
  contractors_getAll?: Maybe<GetAllContractorsResponse>;
  counterparties_get?: Maybe<GetCounterpartyResponse>;
  counterparties_getAll?: Maybe<GetAllCounterpartyResponse>;
  counterparties_getOrCreate?: Maybe<GetOrCreateCounterpartyResponse>;
  currencies_get?: Maybe<GetCurrencyResponse>;
  currencies_list?: Maybe<ListCurrenciesResponse>;
  customer_get?: Maybe<GetCustomerResponse>;
  customer_getAll?: Maybe<GetAllCustomersResponse>;
  customer_getByCustomerId?: Maybe<GetCustomerByCustomerIdResponse>;
  documentTypes_get?: Maybe<GetDocumentTypeResponse>;
  documentTypes_getAll?: Maybe<GetAllDocumentTypeResponse>;
  drivers_get?: Maybe<GetDriverResponse>;
  drivers_getAll?: Maybe<GetAllDriversResponse>;
  drivers_getByEmail?: Maybe<GetDriverByEmailResponse>;
  flightEgisScheduler_get?: Maybe<GetFlightEgisScheduleResponse>;
  flightEgisScheduler_list?: Maybe<ListFlightEgisScheduleResponse>;
  flights_get?: Maybe<GetFlightResponse>;
  flights_getAll?: Maybe<GetAllFlightsResponse>;
  flights_getBatch?: Maybe<GetBatchFlightsResponse>;
  flights_getByNid?: Maybe<GetFlightResponse>;
  flights_getFlightsReport?: Maybe<GetFlightsReportResponse>;
  geobase_get?: Maybe<GetCityResponse>;
  geobase_getCities?: Maybe<GetCitiesResponse>;
  inventories_getFlightInventory?: Maybe<GetFlightInventoryResponse>;
  inventories_getInventory?: Maybe<GetInventoryResponse>;
  legCosts_get?: Maybe<GetLegCostResponse>;
  legCosts_getFromLeg?: Maybe<GetFromLegResponse>;
  legCosts_getFromLegs?: Maybe<GetFromLegsResponse>;
  orders_get?: Maybe<GetOrderResponse>;
  orders_getAll?: Maybe<GetAllOrdersResponse>;
  orders_getByNid?: Maybe<GetOrderResponse>;
  orders_getOrdersReport?: Maybe<GetOrdersReportResponse>;
  passengers_getAll?: Maybe<GetAllPassengersResponse>;
  passengers_getAllByCustomer?: Maybe<GetAllPassengersResponse>;
  passengers_getOne?: Maybe<GetOnePassengerResponse>;
  refundGroups_get?: Maybe<CreateRefundGroupResponse>;
  refundGroups_list?: Maybe<GetListRefundGroupsResponse>;
  refund_get?: Maybe<RefundRuleResponse>;
  refund_list?: Maybe<ListRefundRulesResponse>;
  routeEgisRegistry_get?: Maybe<GetEgisRegistryEntryResponse>;
  routeEgisRegistry_list?: Maybe<ListEgisRegistryEntriesResponse>;
  routeLegs_get?: Maybe<GetRouteLegResponse>;
  routeLegs_getAll?: Maybe<GetAllRouteLegsResponse>;
  routeLegs_getArrivalIds?: Maybe<GetArrivalIdsResponse>;
  routeLegs_getLegByPath?: Maybe<GetLegByPathResponse>;
  routeRefundRules_getBatch?: Maybe<GetRouteRefundRulesResponse>;
  routeRefundRules_getRouteTariffs?: Maybe<GetRouteTariffsResponse>;
  routeSequences_get?: Maybe<GetSequenceResponse>;
  routeSequences_getRouteSequences?: Maybe<GetAllRouteSequencesResponse>;
  routeSettings_getFromRoute?: Maybe<GetFromRouteResponse>;
  routeSettings_getRouteSettings?: Maybe<GetRouteSettingsResponse>;
  routes_get?: Maybe<GetRouteResponse>;
  routes_getAll?: Maybe<GetAllRoutesResponse>;
  sequencePoints_get?: Maybe<GetSequencePointResponse>;
  sequencePoints_getAll?: Maybe<GetAllSequencePointsResponse>;
  stations_getCityByStation?: Maybe<GetCityByStationResponse>;
  stations_getStation?: Maybe<GetStationResponse>;
  stations_getStations?: Maybe<GetStationsResponse>;
  stations_getStationsByCity?: Maybe<GetStationsByCityResponse>;
  stations_getStationsByIds?: Maybe<GetStationsByIdsResponse>;
  tariffs_get?: Maybe<GetTariffResponse>;
  tariffs_getAll?: Maybe<GetAllTariffResponse>;
  tenantsCarriers_getCarriersFromTenant?: Maybe<GetCarriersFromTenantResponse>;
  tenantsCarriers_getTenantsFromCarrier?: Maybe<GetTenantsFromCarrierResponse>;
  tenantsRoutes_getTenantRoutes?: Maybe<TenantRoutesResponse>;
  tenants_get?: Maybe<GetTenantResponse>;
  tenants_getAll?: Maybe<GetAllTenantResponse>;
  tickets_get?: Maybe<GetTicketResponse>;
  tickets_getList?: Maybe<ListTicketsResponse>;
  tickets_getPassengersByStations?: Maybe<GetPassengersByStationsResponse>;
  tickets_getPassengersFromFlight?: Maybe<GetPassengersFromFlightResponse>;
  tickets_getTicketsFromFlight?: Maybe<GetTicketsFromFlightResponse>;
  tickets_getTicketsTemplates?: Maybe<GetTicketsTemplatesResponse>;
  users_getCarrierAndTenant?: Maybe<GetCarrierAndTenantResponse>;
  /** required */
  vehicleMakers_getAll?: Maybe<GetAllVehicleMakersResponse>;
  /** required */
  vehicleMakers_getOne?: Maybe<GetOneVehicleMakerResponse>;
  vehicleModels_getAll?: Maybe<GetAllVehicleModelsResponse>;
  vehicleModels_getOne?: Maybe<GetOneVehicleModelResponse>;
  vehicles_getAll?: Maybe<GetAllVehiclesResponse>;
  vehicles_getOne?: Maybe<GetOneVehicleResponse>;
};


export type QueryAmenities_GetOneArgs = {
  req?: InputMaybe<GetOneRequest_Input>;
};


export type QueryCarriers_GetArgs = {
  req?: InputMaybe<GetCarrierRequest_Input>;
};


export type QueryCarriers_GetAllArgs = {
  req?: InputMaybe<GetAllCarrierRequest_Input>;
};


export type QueryContractors_GetArgs = {
  req?: InputMaybe<GetContractorRequest_Input>;
};


export type QueryCounterparties_GetArgs = {
  req?: InputMaybe<GetCounterpartyRequest_Input>;
};


export type QueryCounterparties_GetAllArgs = {
  req?: InputMaybe<GetAllCounterpartyRequest_Input>;
};


export type QueryCounterparties_GetOrCreateArgs = {
  req?: InputMaybe<GetOrCreateCounterpartyRequest_Input>;
};


export type QueryCurrencies_GetArgs = {
  req?: InputMaybe<GetCurrencyRequest_Input>;
};


export type QueryCurrencies_ListArgs = {
  req?: InputMaybe<ListCurrenciesRequest_Input>;
};


export type QueryCustomer_GetArgs = {
  req?: InputMaybe<GetCustomerRequest_Input>;
};


export type QueryCustomer_GetAllArgs = {
  req?: InputMaybe<GetAllCustomersRequest_Input>;
};


export type QueryCustomer_GetByCustomerIdArgs = {
  req?: InputMaybe<GetCustomerByCustomerIdRequest_Input>;
};


export type QueryDocumentTypes_GetArgs = {
  req?: InputMaybe<GetDocumentTypeRequest_Input>;
};


export type QueryDocumentTypes_GetAllArgs = {
  req?: InputMaybe<GetAllDocumentTypeRequest_Input>;
};


export type QueryDrivers_GetArgs = {
  req?: InputMaybe<GetDriverRequest_Input>;
};


export type QueryDrivers_GetAllArgs = {
  req?: InputMaybe<GetAllDriversRequest_Input>;
};


export type QueryDrivers_GetByEmailArgs = {
  req?: InputMaybe<GetDriverByEmailRequest_Input>;
};


export type QueryFlightEgisScheduler_GetArgs = {
  req?: InputMaybe<GetFlightEgisScheduleRequest_Input>;
};


export type QueryFlightEgisScheduler_ListArgs = {
  req?: InputMaybe<ListFlightEgisScheduleRequest_Input>;
};


export type QueryFlights_GetArgs = {
  req?: InputMaybe<GetFlightRequest_Input>;
};


export type QueryFlights_GetAllArgs = {
  req?: InputMaybe<GetAllFlightsRequest_Input>;
};


export type QueryFlights_GetBatchArgs = {
  req?: InputMaybe<GetBatchFlightsRequest_Input>;
};


export type QueryFlights_GetByNidArgs = {
  req?: InputMaybe<GetFlightByNidRequest_Input>;
};


export type QueryFlights_GetFlightsReportArgs = {
  req?: InputMaybe<GetFlightsReportRequest_Input>;
};


export type QueryGeobase_GetArgs = {
  req?: InputMaybe<GetCityRequest_Input>;
};


export type QueryGeobase_GetCitiesArgs = {
  req?: InputMaybe<GetCitiesRequest_Input>;
};


export type QueryInventories_GetFlightInventoryArgs = {
  req?: InputMaybe<GetFlightInventoryRequest_Input>;
};


export type QueryInventories_GetInventoryArgs = {
  req?: InputMaybe<GetInventoryRequest_Input>;
};


export type QueryLegCosts_GetArgs = {
  req?: InputMaybe<GetLegCostRequest_Input>;
};


export type QueryLegCosts_GetFromLegArgs = {
  req?: InputMaybe<GetFromLegRequest_Input>;
};


export type QueryLegCosts_GetFromLegsArgs = {
  req?: InputMaybe<GetFromLegsRequest_Input>;
};


export type QueryOrders_GetArgs = {
  req?: InputMaybe<GetOrderRequest_Input>;
};


export type QueryOrders_GetAllArgs = {
  req?: InputMaybe<GetAllOrdersRequest_Input>;
};


export type QueryOrders_GetByNidArgs = {
  req?: InputMaybe<GetOrderByNidRequest_Input>;
};


export type QueryOrders_GetOrdersReportArgs = {
  req?: InputMaybe<GetOrdersReportRequest_Input>;
};


export type QueryPassengers_GetAllArgs = {
  req?: InputMaybe<GetAllPassengersRequest_Input>;
};


export type QueryPassengers_GetAllByCustomerArgs = {
  req?: InputMaybe<GetByCustomerRequest_Input>;
};


export type QueryPassengers_GetOneArgs = {
  req?: InputMaybe<GetOneRequest_Input>;
};


export type QueryRefundGroups_GetArgs = {
  req?: InputMaybe<GetRefundGroupRequest_Input>;
};


export type QueryRefundGroups_ListArgs = {
  req?: InputMaybe<GetListRefundGroupsRequest_Input>;
};


export type QueryRefund_GetArgs = {
  req?: InputMaybe<GetRefundRuleRequest_Input>;
};


export type QueryRefund_ListArgs = {
  req?: InputMaybe<ListRefundRulesRequest_Input>;
};


export type QueryRouteEgisRegistry_GetArgs = {
  req?: InputMaybe<GetEgisRegistryEntryRequest_Input>;
};


export type QueryRouteEgisRegistry_ListArgs = {
  req?: InputMaybe<ListEgisRegistryEntriesRequest_Input>;
};


export type QueryRouteLegs_GetArgs = {
  req?: InputMaybe<GetRouteLegRequest_Input>;
};


export type QueryRouteLegs_GetAllArgs = {
  req?: InputMaybe<GetAllRouteLegsRequest_Input>;
};


export type QueryRouteLegs_GetArrivalIdsArgs = {
  req?: InputMaybe<GetArrivalIdsRequest_Input>;
};


export type QueryRouteLegs_GetLegByPathArgs = {
  req?: InputMaybe<GetLegByPathRequest_Input>;
};


export type QueryRouteRefundRules_GetBatchArgs = {
  req?: InputMaybe<GetRouteRefundRulesRequest_Input>;
};


export type QueryRouteRefundRules_GetRouteTariffsArgs = {
  req?: InputMaybe<GetRouteTariffsRequest_Input>;
};


export type QueryRouteSequences_GetArgs = {
  req?: InputMaybe<GetSequenceRequest_Input>;
};


export type QueryRouteSequences_GetRouteSequencesArgs = {
  req?: InputMaybe<GetAllRouteSequencesRequest_Input>;
};


export type QueryRouteSettings_GetFromRouteArgs = {
  req?: InputMaybe<GetFromRouteRequest_Input>;
};


export type QueryRouteSettings_GetRouteSettingsArgs = {
  req?: InputMaybe<GetRouteSettingsRequest_Input>;
};


export type QueryRoutes_GetArgs = {
  req?: InputMaybe<GetRouteRequest_Input>;
};


export type QueryRoutes_GetAllArgs = {
  req?: InputMaybe<GetAllRoutesRequest_Input>;
};


export type QuerySequencePoints_GetArgs = {
  req?: InputMaybe<GetSequencePointRequest_Input>;
};


export type QuerySequencePoints_GetAllArgs = {
  req?: InputMaybe<GetAllSequencePointsRequest_Input>;
};


export type QueryStations_GetCityByStationArgs = {
  req?: InputMaybe<GetCityByStationRequest_Input>;
};


export type QueryStations_GetStationArgs = {
  req?: InputMaybe<GetStationRequest_Input>;
};


export type QueryStations_GetStationsArgs = {
  req?: InputMaybe<GetStationsRequest_Input>;
};


export type QueryStations_GetStationsByCityArgs = {
  req?: InputMaybe<GetStationsByCityRequest_Input>;
};


export type QueryStations_GetStationsByIdsArgs = {
  req?: InputMaybe<GetStationsByIdsRequest_Input>;
};


export type QueryTariffs_GetArgs = {
  req?: InputMaybe<GetTariffRequest_Input>;
};


export type QueryTariffs_GetAllArgs = {
  req?: InputMaybe<GetAllTariffRequest_Input>;
};


export type QueryTenantsCarriers_GetCarriersFromTenantArgs = {
  req?: InputMaybe<GetCarriersFromTenantRequest_Input>;
};


export type QueryTenantsCarriers_GetTenantsFromCarrierArgs = {
  req?: InputMaybe<GetTenantsFromCarrierRequest_Input>;
};


export type QueryTenantsRoutes_GetTenantRoutesArgs = {
  req?: InputMaybe<TenantRoutesRequest_Input>;
};


export type QueryTenants_GetArgs = {
  req?: InputMaybe<GetTenantRequest_Input>;
};


export type QueryTenants_GetAllArgs = {
  req?: InputMaybe<GetAllTenantRequest_Input>;
};


export type QueryTickets_GetArgs = {
  req?: InputMaybe<GetTicketRequest_Input>;
};


export type QueryTickets_GetListArgs = {
  req?: InputMaybe<ListTicketsRequest_Input>;
};


export type QueryTickets_GetPassengersByStationsArgs = {
  req?: InputMaybe<GetPassengersByStationsRequest_Input>;
};


export type QueryTickets_GetPassengersFromFlightArgs = {
  req?: InputMaybe<GetPassengersFromFlightRequest_Input>;
};


export type QueryTickets_GetTicketsFromFlightArgs = {
  req?: InputMaybe<GetTicketsFromFlightRequest_Input>;
};


export type QueryUsers_GetCarrierAndTenantArgs = {
  req?: InputMaybe<GetCarrierAndTenantRequest_Input>;
};


export type QueryVehicleMakers_GetOneArgs = {
  req?: InputMaybe<GetOneRequest_Input>;
};


export type QueryVehicleModels_GetAllArgs = {
  req?: InputMaybe<GetAllVehicleModelsRequest_Input>;
};


export type QueryVehicleModels_GetOneArgs = {
  req?: InputMaybe<GetOneRequest_Input>;
};


export type QueryVehicles_GetOneArgs = {
  req?: InputMaybe<GetOneRequest_Input>;
};

export type RefundGroup = {
  __typename?: 'RefundGroup';
  counterpartyId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type RefundRule = {
  __typename?: 'RefundRule';
  action: Action;
  condition: Condition;
  id: Scalars['String']['output'];
  note: Scalars['String']['output'];
  refundGroupId: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type RefundRuleResponse = {
  __typename?: 'RefundRuleResponse';
  rule: RefundRule;
};

export type RemoveAmenityRequest_Input = {
  amenityId: Scalars['String']['input'];
  vehicleId: Scalars['String']['input'];
};

export type RemoveAmenityResponse = {
  __typename?: 'RemoveAmenityResponse';
  amenityId: Scalars['String']['output'];
};

export type RemoveByCustomerRequest_Input = {
  customerId: Scalars['String']['input'];
};

export type RemoveCarrierFromUserRequest_Input = {
  carrierId: Scalars['String']['input'];
  userEmail: Scalars['String']['input'];
};

export type RemoveCarrierFromUserResponse = {
  __typename?: 'RemoveCarrierFromUserResponse';
  success: Scalars['Boolean']['output'];
};

export type RemoveCarrierRequest_Input = {
  id: Scalars['String']['input'];
};

export type RemoveCarrierResponse = {
  __typename?: 'RemoveCarrierResponse';
  id: Scalars['String']['output'];
};

export type RemoveContractorRequest_Input = {
  id: Scalars['String']['input'];
};

export type RemoveContractorResponse = {
  __typename?: 'RemoveContractorResponse';
  id: Scalars['String']['output'];
};

export type RemoveCounterpartyRequest_Input = {
  id: Scalars['String']['input'];
};

export type RemoveCounterpartyResponse = {
  __typename?: 'RemoveCounterpartyResponse';
  id: Scalars['String']['output'];
};

export type RemoveDocumentTypeRequest_Input = {
  id: Scalars['Int']['input'];
};

export type RemoveDocumentTypeResponse = {
  __typename?: 'RemoveDocumentTypeResponse';
  id: Scalars['Int']['output'];
};

export type RemoveDriverRequest_Input = {
  id: Scalars['String']['input'];
};

export type RemoveDriverResponse = {
  __typename?: 'RemoveDriverResponse';
  id: Scalars['String']['output'];
};

export type RemoveEgisRegistryEntryRequest_Input = {
  id: Scalars['String']['input'];
};

export type RemoveEgisRegistryEntryResponse = {
  __typename?: 'RemoveEgisRegistryEntryResponse';
  removedEntry: EgisRegistryEntry;
};

export type RemoveFlightEgisScheduleRequest_Input = {
  id: Scalars['String']['input'];
};

export type RemoveFlightEgisScheduleResponse = {
  __typename?: 'RemoveFlightEgisScheduleResponse';
  flightEgisSchedule: FlightEgisSchedule;
};

export type RemoveFlightReferencesRequest_Input = {
  flightId: Scalars['String']['input'];
};

export type RemoveFlightReferencesResponse = {
  __typename?: 'RemoveFlightReferencesResponse';
  id: Scalars['String']['output'];
};

export type RemoveFlightRequest_Input = {
  id: Scalars['String']['input'];
};

export type RemoveFlightResponse = {
  __typename?: 'RemoveFlightResponse';
  id: Scalars['String']['output'];
};

export type RemoveRefundGroupRequest_Input = {
  id: Scalars['String']['input'];
};

export type RemoveRefundGroupResponse = {
  __typename?: 'RemoveRefundGroupResponse';
  refundGroup: RefundGroup;
};

export type RemoveRefundRuleRequest_Input = {
  id: Scalars['String']['input'];
};

export type RemoveRequest = {
  __typename?: 'RemoveRequest';
  id: Scalars['String']['output'];
};

export type RemoveRequest_Input = {
  id: Scalars['String']['input'];
};

export type RemoveResponse = {
  __typename?: 'RemoveResponse';
  id: Scalars['String']['output'];
};

export type RemoveRouteLegRequest_Input = {
  id: Scalars['String']['input'];
};

export type RemoveRouteLegResponse = {
  __typename?: 'RemoveRouteLegResponse';
  routeLeg: RouteLeg;
};

export type RemoveRouteLegsBatchRequest_Input = {
  ids: Array<InputMaybe<Scalars['String']['input']>>;
};

export type RemoveRouteLegsBatchResponse = {
  __typename?: 'RemoveRouteLegsBatchResponse';
  routeLegs: Array<Maybe<RouteLeg>>;
};

export type RemoveRouteRefundGroupRequest_Input = {
  id: Scalars['String']['input'];
};

export type RemoveRouteRefundGroupResponse = {
  __typename?: 'RemoveRouteRefundGroupResponse';
  routeRefundRule: RouteRefundRule;
};

export type RemoveRouteRequest_Input = {
  id: Scalars['String']['input'];
};

export type RemoveRouteResponse = {
  __typename?: 'RemoveRouteResponse';
  id: Scalars['String']['output'];
};

export type RemoveRouteTariffRequest_Input = {
  routeId: Scalars['String']['input'];
  tariffId: Scalars['String']['input'];
};

export type RemoveRouteTariffResponse = {
  __typename?: 'RemoveRouteTariffResponse';
  rules?: Maybe<Array<Maybe<RouteRefundRule>>>;
};

export type RemoveSequencePointRequest_Input = {
  id: Scalars['String']['input'];
};

export type RemoveSequencePointResponse = {
  __typename?: 'RemoveSequencePointResponse';
  sequencePoint: SequencePoint;
};

export type RemoveStationRequest_Input = {
  id: Scalars['String']['input'];
};

export type RemoveStationResponse = {
  __typename?: 'RemoveStationResponse';
  station: Station;
};

export type RemoveTariffRequest_Input = {
  id: Scalars['String']['input'];
};

export type RemoveTariffResponse = {
  __typename?: 'RemoveTariffResponse';
  id: Scalars['String']['output'];
};

export type RemoveTenantCarrierRequest_Input = {
  carrierId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type RemoveTenantCarrierResponse = {
  __typename?: 'RemoveTenantCarrierResponse';
  carrierId: Scalars['String']['output'];
  tenantId: Scalars['String']['output'];
};

export type RemoveTenantFromUserRequest_Input = {
  tenantId: Scalars['String']['input'];
  userEmail: Scalars['String']['input'];
};

export type RemoveTenantFromUserResponse = {
  __typename?: 'RemoveTenantFromUserResponse';
  success: Scalars['Boolean']['output'];
};

export type RemoveTenantRequest_Input = {
  id: Scalars['String']['input'];
};

export type RemoveTenantResponse = {
  __typename?: 'RemoveTenantResponse';
  id: Scalars['String']['output'];
};

export type RemoveTenantRoutesRequest_Input = {
  id: Scalars['String']['input'];
};

export type RemoveTenantRoutesResponse = {
  __typename?: 'RemoveTenantRoutesResponse';
  tenantRoute: TenantRoutes;
};

export type ReportItem = {
  __typename?: 'ReportItem';
  arrivalDate: Scalars['String']['output'];
  arrivalStation: Scalars['String']['output'];
  carrierInn: Scalars['String']['output'];
  category: Scalars['String']['output'];
  confirmDate: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  customerId: Scalars['String']['output'];
  date: Scalars['String']['output'];
  departDate: Scalars['String']['output'];
  departStation: Scalars['String']['output'];
  externalTicketId: Scalars['String']['output'];
  fare: Scalars['Float']['output'];
  orderId: Scalars['String']['output'];
  passenger: Scalars['String']['output'];
  paymentMethod: Scalars['String']['output'];
  repayment: Scalars['Float']['output'];
  routeNum: Scalars['String']['output'];
  status: Scalars['String']['output'];
  ticketClass: Scalars['String']['output'];
  ticketPrice: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type ReturnTicketRequest_Input = {
  agent: Agent_Input;
  ticketIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type ReturnTicketResponse = {
  __typename?: 'ReturnTicketResponse';
  tickets: Array<Maybe<Ticket>>;
};

export enum RideType {
  Booked = 'BOOKED',
  NotSpecifiedRideType = 'NOT_SPECIFIED_RIDE_TYPE',
  Regular = 'REGULAR',
  Shuttle = 'SHUTTLE',
  Taxi = 'TAXI'
}

export enum RideType_Input {
  Booked = 'BOOKED',
  NotSpecifiedRideType = 'NOT_SPECIFIED_RIDE_TYPE',
  Regular = 'REGULAR',
  Shuttle = 'SHUTTLE',
  Taxi = 'TAXI'
}

export type Route = {
  __typename?: 'Route';
  arrivalCityId: Scalars['Int']['output'];
  arrivalCityName: Scalars['String']['output'];
  arrivalId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  departureCityId: Scalars['Int']['output'];
  departureCityName: Scalars['String']['output'];
  departureId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export enum RouteClass {
  CustomRoute = 'CUSTOM_ROUTE',
  NotSpecifiedRouteClass = 'NOT_SPECIFIED_ROUTE_CLASS',
  RegularRoute = 'REGULAR_ROUTE'
}

export enum RouteClass_Input {
  CustomRoute = 'CUSTOM_ROUTE',
  NotSpecifiedRouteClass = 'NOT_SPECIFIED_ROUTE_CLASS',
  RegularRoute = 'REGULAR_ROUTE'
}

export type RouteLeg = {
  __typename?: 'RouteLeg';
  arrivalPointId: Scalars['Int']['output'];
  createdAt: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  departurePointId: Scalars['Int']['output'];
  flightId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  nid: Scalars['String']['output'];
  routeId: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type RouteRefundRule = {
  __typename?: 'RouteRefundRule';
  id: Scalars['String']['output'];
  refundGroupId: Scalars['String']['output'];
  routeId: Scalars['String']['output'];
  tariffId: Scalars['String']['output'];
};

export type RouteSequence = {
  __typename?: 'RouteSequence';
  createdAt: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  flightId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  routeId: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
  updatedAt: Scalars['String']['output'];
};

export type RouteSettingsType = {
  __typename?: 'RouteSettingsType';
  acquiringFeePercent: Scalars['Float']['output'];
  allowBookingAfterDeparture: Scalars['Boolean']['output'];
  allowReferralCodesByDefault: Scalars['Boolean']['output'];
  animalInfo: Scalars['Boolean']['output'];
  animalRules?: Maybe<Scalars['String']['output']>;
  baggageInfo: Scalars['Boolean']['output'];
  baggageRules?: Maybe<Scalars['String']['output']>;
  bookingTimeRestriction: Scalars['Int']['output'];
  cancelOrderTime: Scalars['Int']['output'];
  cardPaymentOperator: Scalars['Boolean']['output'];
  cashPayment: Scalars['Boolean']['output'];
  charterer: Scalars['String']['output'];
  class: RouteClass;
  considerPassengerRating: Scalars['Boolean']['output'];
  currencyId: Scalars['String']['output'];
  defaultRideType: RideType;
  defaultRouteNumber?: Maybe<Scalars['String']['output']>;
  door2doorMode: Scalars['Boolean']['output'];
  enableCentralizedCalls: Scalars['Boolean']['output'];
  enablePushNotifications: Scalars['Boolean']['output'];
  expectedLoadConfig: Scalars['Boolean']['output'];
  fixedSeats: Scalars['Boolean']['output'];
  flightId?: Maybe<Scalars['String']['output']>;
  globalOperatorAccess: Scalars['Boolean']['output'];
  hideBlockedTrips: Scalars['Boolean']['output'];
  hideDriverInfo: Scalars['Boolean']['output'];
  hideDriverPhone: Scalars['Boolean']['output'];
  hidePassengerNumber: Scalars['Boolean']['output'];
  hideUnassignedTrips: Scalars['Boolean']['output'];
  hideVehicleData: Scalars['Boolean']['output'];
  hideVehicleNumber: Scalars['Boolean']['output'];
  hideVehicleRegNumber: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  lowRatingLoadThreshold: Scalars['Int']['output'];
  maxBookingDays?: Maybe<Scalars['Int']['output']>;
  maxOrdersPerUser?: Maybe<Scalars['Int']['output']>;
  milesAccrualPercent?: Maybe<Scalars['Float']['output']>;
  milesPayment: Scalars['Boolean']['output'];
  onlinePaymentCoefficient?: Maybe<Scalars['Float']['output']>;
  onlinePurchaseRegular: Scalars['Boolean']['output'];
  onlineTicketPurchase: Scalars['Boolean']['output'];
  operatorTicketTemplate?: Maybe<Scalars['Int']['output']>;
  passengerTicketTemplate: Scalars['String']['output'];
  requirePassportData: Scalars['Boolean']['output'];
  restrictBooking: Scalars['Boolean']['output'];
  routeId?: Maybe<Scalars['String']['output']>;
  seatSelection: Scalars['Boolean']['output'];
  sendToEgis: Scalars['Boolean']['output'];
  settlementType: SettlementType;
  smsMessageBeforeDeparture: Scalars['Int']['output'];
  smsOrderActions: Scalars['Boolean']['output'];
  type: RouteType;
  useReferralPromoCodes: Scalars['Boolean']['output'];
};

export enum RouteType {
  AdjacentInterregional = 'ADJACENT_INTERREGIONAL',
  Intermunicipal = 'INTERMUNICIPAL',
  International = 'INTERNATIONAL',
  Interregional = 'INTERREGIONAL',
  Municipal = 'MUNICIPAL',
  NotSpecifiedRouteType = 'NOT_SPECIFIED_ROUTE_TYPE'
}

export enum RouteType_Input {
  AdjacentInterregional = 'ADJACENT_INTERREGIONAL',
  Intermunicipal = 'INTERMUNICIPAL',
  International = 'INTERNATIONAL',
  Interregional = 'INTERREGIONAL',
  Municipal = 'MUNICIPAL',
  NotSpecifiedRouteType = 'NOT_SPECIFIED_ROUTE_TYPE'
}

export type SalePayload_Input = {
  customerId: Scalars['String']['input'];
  passenger: PassengerPayload_Input;
  seatId?: InputMaybe<Scalars['Int']['input']>;
  ticketType: Scalars['String']['input'];
};

export type SearchFlightsRequest_Input = {
  arrivalCityId?: InputMaybe<Scalars['Int']['input']>;
  arrivalStationId?: InputMaybe<Scalars['String']['input']>;
  departureTime: Scalars['String']['input'];
  dispatchCityId?: InputMaybe<Scalars['Int']['input']>;
  dispatchStationId?: InputMaybe<Scalars['String']['input']>;
};

export type SearchFlightsResponse = {
  __typename?: 'SearchFlightsResponse';
  flights: Array<Maybe<Flight>>;
};

export type SearchRouteSequencesRequest_Input = {
  arrivalStationId: Scalars['String']['input'];
  dispatchStationId: Scalars['String']['input'];
};

export type SearchRouteSequencesResponse = {
  __typename?: 'SearchRouteSequencesResponse';
  routeSequences: Array<Maybe<RouteSequence>>;
};

export type SearchRoutesLegsRequest_Input = {
  arrivalCityId?: InputMaybe<Scalars['Int']['input']>;
  arrivalPointId?: InputMaybe<Scalars['String']['input']>;
  departureCityId?: InputMaybe<Scalars['Int']['input']>;
  departureDate?: InputMaybe<Scalars['String']['input']>;
  departurePointId?: InputMaybe<Scalars['String']['input']>;
  flightsIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type SearchRoutesLegsResponse = {
  __typename?: 'SearchRoutesLegsResponse';
  routesLegs: Array<Maybe<RouteLeg>>;
};

export type SearchRoutesRequest_Input = {
  arrivalId?: InputMaybe<Scalars['String']['input']>;
  departureId?: InputMaybe<Scalars['String']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
};

export type SearchRoutesResponse = {
  __typename?: 'SearchRoutesResponse';
  routes: Array<Maybe<Route>>;
};

export type SendSmsRequest_Input = {
  carrierId?: InputMaybe<Scalars['String']['input']>;
  category: MessageCategory_Input;
  clientId?: InputMaybe<Scalars['String']['input']>;
  context?: InputMaybe<Scalars['String']['input']>;
  geo?: InputMaybe<Scalars['String']['input']>;
  partnerId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
  priority?: InputMaybe<MessagePriority_Input>;
  provider?: InputMaybe<Scalars['String']['input']>;
  shortText?: InputMaybe<Scalars['String']['input']>;
  shortenLinks?: InputMaybe<Scalars['Boolean']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  templateCode?: InputMaybe<Scalars['String']['input']>;
};

export type SendSmsResponse = {
  __typename?: 'SendSmsResponse';
  status: Scalars['String']['output'];
};

export type SendSmsToFlightPassengersRequest_Input = {
  category?: InputMaybe<MessageCategory_Input>;
  flightId: Scalars['String']['input'];
  message: Scalars['String']['input'];
  priority?: InputMaybe<MessagePriority_Input>;
};

export type SendSmsToFlightPassengersResponse = {
  __typename?: 'SendSmsToFlightPassengersResponse';
  status: Scalars['Boolean']['output'];
};

export type SequencePoint = {
  __typename?: 'SequencePoint';
  cityId: Scalars['Int']['output'];
  cityName: Scalars['String']['output'];
  distance?: Maybe<Scalars['Int']['output']>;
  duration: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  important: Scalars['Boolean']['output'];
  isActive: Scalars['Boolean']['output'];
  isAllowedGetOff: Scalars['Boolean']['output'];
  isAllowedSeat: Scalars['Boolean']['output'];
  nid: Scalars['String']['output'];
  pointId: Scalars['String']['output'];
  positionOrder: Scalars['Int']['output'];
  sequenceId: Scalars['String']['output'];
};

export enum SettlementType {
  NotSpecifiedSettlement = 'NOT_SPECIFIED_SETTLEMENT',
  Percent = 'PERCENT',
  RevShare = 'REV_SHARE'
}

export enum SettlementType_Input {
  NotSpecifiedSettlement = 'NOT_SPECIFIED_SETTLEMENT',
  Percent = 'PERCENT',
  RevShare = 'REV_SHARE'
}

export type Station = {
  __typename?: 'Station';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  geoId: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  okato?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  stopType?: Maybe<EStopType>;
  timezone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type SuggestRequest_Input = {
  userInput: Scalars['String']['input'];
};

export type SuggestResponse = {
  __typename?: 'SuggestResponse';
  cities: Array<Maybe<Geoname>>;
};

export type Tariff = {
  __typename?: 'Tariff';
  currency: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
  isGlobal: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nid: Scalars['String']['output'];
  percentDiscount: Scalars['Float']['output'];
  tenantId: Scalars['String']['output'];
};

export type Tariff_Input = {
  currency: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isDefault: Scalars['Boolean']['input'];
  isGlobal: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  nid: Scalars['String']['input'];
  percentDiscount: Scalars['Float']['input'];
  tenantId: Scalars['String']['input'];
};

export type Tenant = {
  __typename?: 'Tenant';
  address: Scalars['String']['output'];
  billingId: Scalars['String']['output'];
  carriers: Array<Maybe<Scalars['String']['output']>>;
  counterpartyId: Scalars['String']['output'];
  country: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export type TenantRoutes = {
  __typename?: 'TenantRoutes';
  id?: Maybe<Scalars['String']['output']>;
  routeId: Scalars['String']['output'];
  tenantId: Scalars['String']['output'];
};

export type TenantRoutesRequest_Input = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  tenantId: Scalars['String']['input'];
};

export type TenantRoutesResponse = {
  __typename?: 'TenantRoutesResponse';
  routes: Array<Maybe<TenantRoutes>>;
};

export type Ticket = {
  __typename?: 'Ticket';
  arrivalCityId: Scalars['Int']['output'];
  arrivalDate: Scalars['String']['output'];
  arrivalStation: Scalars['String']['output'];
  arrivalStationId: Scalars['String']['output'];
  carrierId?: Maybe<Scalars['String']['output']>;
  carrierInn: Scalars['String']['output'];
  carrierName?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  confirmDate?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  customerId: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  departAddress?: Maybe<Scalars['String']['output']>;
  departCityId: Scalars['Int']['output'];
  departDate: Scalars['String']['output'];
  departStation: Scalars['String']['output'];
  departStationId: Scalars['String']['output'];
  externalTicketId: Scalars['Int']['output'];
  fare: Scalars['Float']['output'];
  fees: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  insuranceInfo: Scalars['String']['output'];
  inventoryId: Scalars['String']['output'];
  nid: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  passengerId: Scalars['String']['output'];
  platform?: Maybe<Scalars['String']['output']>;
  repayment: Scalars['Float']['output'];
  returnedDate?: Maybe<Scalars['String']['output']>;
  routeName: Scalars['String']['output'];
  routeNum?: Maybe<Scalars['String']['output']>;
  series?: Maybe<Scalars['String']['output']>;
  status: EOrderStatus;
  ticketClass: ETicketClass;
  ticketPrice: Scalars['Float']['output'];
  ticketUrl: Scalars['String']['output'];
  typeId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type TicketFare_Input = {
  fare: Scalars['Float']['input'];
  ticketId: Scalars['String']['input'];
};

export type TicketPrice = {
  __typename?: 'TicketPrice';
  price: Scalars['Float']['output'];
};

export type TicketReportRequest_Input = {
  carrierId?: InputMaybe<Scalars['String']['input']>;
  currency: Array<InputMaybe<Scalars['String']['input']>>;
  fromDate: Scalars['String']['input'];
  paymentType: Array<InputMaybe<Scalars['String']['input']>>;
  targetColumnForDateSearch?: InputMaybe<ETargetColumn_Input>;
  toDate: Scalars['String']['input'];
};

export type TicketReportResponse = {
  __typename?: 'TicketReportResponse';
  items: Array<Maybe<ReportItem>>;
};

export type UpdateAmenityFields_Input = {
  amenity?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  vehicleType?: InputMaybe<VehicleType_Input>;
};

export type UpdateAmenityRequest_Input = {
  fields: UpdateAmenityFields_Input;
  id: Scalars['String']['input'];
};

export type UpdateAmenityResponse = {
  __typename?: 'UpdateAmenityResponse';
  amenity: Amenity;
};

export type UpdateCarrierFields_Input = {
  counterpartyId?: InputMaybe<Scalars['String']['input']>;
  egisOTBId?: InputMaybe<Scalars['Int']['input']>;
  operatingArea?: InputMaybe<Scalars['String']['input']>;
  supportEmail?: InputMaybe<Scalars['String']['input']>;
  supportHours?: InputMaybe<Scalars['String']['input']>;
  supportPhone?: InputMaybe<Scalars['String']['input']>;
  tenants: Array<InputMaybe<Scalars['String']['input']>>;
};

export type UpdateCarrierRequest_Input = {
  fields: UpdateCarrierFields_Input;
  id: Scalars['String']['input'];
};

export type UpdateCarrierResponse = {
  __typename?: 'UpdateCarrierResponse';
  carrier: Carrier;
};

export type UpdateContractorFields_Input = {
  addresses: Array<InputMaybe<Scalars['String']['input']>>;
  country?: InputMaybe<Scalars['String']['input']>;
  emails: Array<InputMaybe<Scalars['String']['input']>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  legalEntityForm?: InputMaybe<Scalars['String']['input']>;
  midName?: InputMaybe<Scalars['String']['input']>;
  phones: Array<InputMaybe<Scalars['String']['input']>>;
};

export type UpdateContractorRequest_Input = {
  fields: UpdateContractorFields_Input;
  id: Scalars['String']['input'];
};

export type UpdateContractorResponse = {
  __typename?: 'UpdateContractorResponse';
  contractor: Contractor;
};

export type UpdateCounterpartyFields_Input = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  billingId?: InputMaybe<Scalars['Int']['input']>;
  branchAddress1?: InputMaybe<Scalars['String']['input']>;
  branchAddress2?: InputMaybe<Scalars['String']['input']>;
  branchCity?: InputMaybe<Scalars['String']['input']>;
  branchCountry?: InputMaybe<Scalars['String']['input']>;
  branchName?: InputMaybe<Scalars['String']['input']>;
  branchStateProvince?: InputMaybe<Scalars['String']['input']>;
  branchZipCode?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  registrationAuthority?: InputMaybe<Scalars['String']['input']>;
  registrationDate?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  stateProvince?: InputMaybe<Scalars['String']['input']>;
  swiftBic?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCounterpartyRequest_Input = {
  fields: UpdateCounterpartyFields_Input;
  id: Scalars['String']['input'];
};

export type UpdateCounterpartyResponse = {
  __typename?: 'UpdateCounterpartyResponse';
  counterparty: Counterparty;
};

export type UpdateCurrencyRequest_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  exchangeRate?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCurrencyResponse = {
  __typename?: 'UpdateCurrencyResponse';
  currency: Currency;
};

export type UpdateDocumentTypeFields_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDocumentTypeRequest_Input = {
  fields: UpdateDocumentTypeFields_Input;
  id: Scalars['Int']['input'];
};

export type UpdateDocumentTypeResponse = {
  __typename?: 'UpdateDocumentTypeResponse';
  documentType: DocumentType;
};

export type UpdateDriverFields_Input = {
  birthday?: InputMaybe<Scalars['String']['input']>;
  carrierId?: InputMaybe<Scalars['String']['input']>;
  documentId?: InputMaybe<Scalars['Int']['input']>;
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  midName?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDriverRequest_Input = {
  fields: UpdateDriverFields_Input;
  id: Scalars['String']['input'];
};

export type UpdateDriverResponse = {
  __typename?: 'UpdateDriverResponse';
  driver: Driver;
};

export type UpdateFlightEgisScheduleRequest_Input = {
  dayOfWeek?: InputMaybe<Scalars['Int']['input']>;
  egisRegistryId?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  routeId?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFlightEgisScheduleResponse = {
  __typename?: 'UpdateFlightEgisScheduleResponse';
  flightEgisSchedule: FlightEgisSchedule;
};

export type UpdateFlightFields_Input = {
  carrierId?: InputMaybe<Scalars['String']['input']>;
  departureTime?: InputMaybe<Scalars['String']['input']>;
  driver1?: InputMaybe<Scalars['String']['input']>;
  driver2?: InputMaybe<Scalars['String']['input']>;
  driver3?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  rideNumber?: InputMaybe<Scalars['Int']['input']>;
  rideType?: InputMaybe<EFlightType_Input>;
  routeId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EFlightStatus_Input>;
  vehicleId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFlightRequest_Input = {
  fields: UpdateFlightFields_Input;
  id: Scalars['String']['input'];
};

export type UpdateFlightResponse = {
  __typename?: 'UpdateFlightResponse';
  flight?: Maybe<Flight>;
};

export type UpdateLegsCostsPayload_Input = {
  cost?: InputMaybe<Scalars['Float']['input']>;
  currencyId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type UpdateLegsCostsRequest_Input = {
  legsCosts: Array<InputMaybe<UpdateLegsCostsPayload_Input>>;
};

export type UpdateLegsCostsResponse = {
  __typename?: 'UpdateLegsCostsResponse';
  legsCosts: Array<Maybe<LegCost>>;
};

export type UpdatePassengerFields_Input = {
  birthdayYear?: InputMaybe<Scalars['Int']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  documentTypeId?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePassengerRequest_Input = {
  fields: UpdatePassengerFields_Input;
  id: Scalars['String']['input'];
};

export type UpdatePassengerResponse = {
  __typename?: 'UpdatePassengerResponse';
  passenger: Passenger;
};

export type UpdatePositionRequest_Input = {
  points: Array<InputMaybe<PositionedPoint_Input>>;
  sequenceId: Scalars['String']['input'];
};

export type UpdatePositionResponse = {
  __typename?: 'UpdatePositionResponse';
  sequenceId: Scalars['String']['output'];
};

export type UpdateRefundRuleRequest_Input = {
  action?: InputMaybe<Action_Input>;
  condition?: InputMaybe<Condition_Input>;
  id: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateRouteFields_Input = {
  arrivalId?: InputMaybe<Scalars['String']['input']>;
  departureId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  refundGroupId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRouteRequest_Input = {
  fields: UpdateRouteFields_Input;
  id: Scalars['String']['input'];
};

export type UpdateRouteResponse = {
  __typename?: 'UpdateRouteResponse';
  route: Route;
};

export type UpdateRouteSequenceRequest_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateRouteSequenceResponse = {
  __typename?: 'UpdateRouteSequenceResponse';
  routeSequence?: Maybe<RouteSequence>;
};

export type UpdateRouteSettingsRequest_Input = {
  acquiringFeePercent?: InputMaybe<Scalars['Float']['input']>;
  allowBookingAfterDeparture?: InputMaybe<Scalars['Boolean']['input']>;
  allowReferralCodesByDefault?: InputMaybe<Scalars['Boolean']['input']>;
  animalInfo?: InputMaybe<Scalars['Boolean']['input']>;
  animalRules?: InputMaybe<Scalars['String']['input']>;
  baggageInfo?: InputMaybe<Scalars['Boolean']['input']>;
  baggageRules?: InputMaybe<Scalars['String']['input']>;
  bookingTimeRestriction?: InputMaybe<Scalars['Int']['input']>;
  cancelOrderTime?: InputMaybe<Scalars['Int']['input']>;
  cardPaymentOperator?: InputMaybe<Scalars['Boolean']['input']>;
  cashPayment?: InputMaybe<Scalars['Boolean']['input']>;
  charterer?: InputMaybe<Scalars['String']['input']>;
  class?: InputMaybe<RouteClass_Input>;
  considerPassengerRating?: InputMaybe<Scalars['Boolean']['input']>;
  currencyId?: InputMaybe<Scalars['String']['input']>;
  defaultRideType?: InputMaybe<RideType_Input>;
  defaultRouteNumber?: InputMaybe<Scalars['String']['input']>;
  door2doorMode?: InputMaybe<Scalars['Boolean']['input']>;
  enableCentralizedCalls?: InputMaybe<Scalars['Boolean']['input']>;
  enablePushNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  expectedLoadConfig?: InputMaybe<Scalars['Boolean']['input']>;
  fixedSeats?: InputMaybe<Scalars['Boolean']['input']>;
  flightId?: InputMaybe<Scalars['String']['input']>;
  globalOperatorAccess?: InputMaybe<Scalars['Boolean']['input']>;
  hideBlockedTrips?: InputMaybe<Scalars['Boolean']['input']>;
  hideDriverInfo?: InputMaybe<Scalars['Boolean']['input']>;
  hideDriverPhone?: InputMaybe<Scalars['Boolean']['input']>;
  hidePassengerNumber?: InputMaybe<Scalars['Boolean']['input']>;
  hideUnassignedTrips?: InputMaybe<Scalars['Boolean']['input']>;
  hideVehicleData?: InputMaybe<Scalars['Boolean']['input']>;
  hideVehicleNumber?: InputMaybe<Scalars['Boolean']['input']>;
  hideVehicleRegNumber?: InputMaybe<Scalars['Boolean']['input']>;
  lowRatingLoadThreshold?: InputMaybe<Scalars['Int']['input']>;
  maxBookingDays?: InputMaybe<Scalars['Int']['input']>;
  maxOrdersPerUser?: InputMaybe<Scalars['Int']['input']>;
  milesAccrualPercent?: InputMaybe<Scalars['Float']['input']>;
  milesPayment?: InputMaybe<Scalars['Boolean']['input']>;
  onlinePaymentCoefficient?: InputMaybe<Scalars['Float']['input']>;
  onlinePurchaseRegular?: InputMaybe<Scalars['Boolean']['input']>;
  onlineTicketPurchase?: InputMaybe<Scalars['Boolean']['input']>;
  operatorTicketTemplate?: InputMaybe<Scalars['Int']['input']>;
  passengerTicketTemplate?: InputMaybe<Scalars['String']['input']>;
  requirePassportData?: InputMaybe<Scalars['Boolean']['input']>;
  restrictBooking?: InputMaybe<Scalars['Boolean']['input']>;
  routeId?: InputMaybe<Scalars['String']['input']>;
  seatSelection?: InputMaybe<Scalars['Boolean']['input']>;
  sendToEgis?: InputMaybe<Scalars['Boolean']['input']>;
  settlementType?: InputMaybe<SettlementType_Input>;
  smsMessageBeforeDeparture?: InputMaybe<Scalars['Int']['input']>;
  smsOrderActions?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<RouteType_Input>;
  useReferralPromoCodes?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateRouteSettingsResponse = {
  __typename?: 'UpdateRouteSettingsResponse';
  routeSettings: RouteSettingsType;
};

export type UpdateSequencePointFields_Input = {
  distance?: InputMaybe<Scalars['Int']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  important?: InputMaybe<Scalars['Boolean']['input']>;
  isAllowedGetOff?: InputMaybe<Scalars['Boolean']['input']>;
  isAllowedSeat?: InputMaybe<Scalars['Boolean']['input']>;
  pointId?: InputMaybe<Scalars['String']['input']>;
  sequenceId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSequencePointRequest_Input = {
  fields: UpdateSequencePointFields_Input;
  id: Scalars['String']['input'];
};

export type UpdateSequencePointResponse = {
  __typename?: 'UpdateSequencePointResponse';
  sequencePoint: SequencePoint;
};

export type UpdateStationRequest_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  geoId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  latitude?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  okato?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  stopType?: InputMaybe<EStopType_Input>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStationResponse = {
  __typename?: 'UpdateStationResponse';
  station: Station;
};

export type UpdateTariffFields_Input = {
  currency?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  isGlobal?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  percentDiscount?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateTariffRequest_Input = {
  fields: UpdateTariffFields_Input;
  id: Scalars['String']['input'];
};

export type UpdateTariffResponse = {
  __typename?: 'UpdateTariffResponse';
  tariff: Tariff;
};

export type UpdateTenantFields_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  billingId?: InputMaybe<Scalars['String']['input']>;
  counterpartyId?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTenantRequest_Input = {
  fields: UpdateTenantFields_Input;
  id: Scalars['String']['input'];
};

export type UpdateTenantResponse = {
  __typename?: 'UpdateTenantResponse';
  tenant: Tenant;
};

export type UpdateVehicleFields_Input = {
  amenities: Array<InputMaybe<Scalars['String']['input']>>;
  baggageCapacity?: InputMaybe<Scalars['Int']['input']>;
  carrierId?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  isBranded?: InputMaybe<Scalars['Boolean']['input']>;
  makerId?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  productionYear?: InputMaybe<Scalars['Int']['input']>;
  regPlate?: InputMaybe<Scalars['String']['input']>;
  seatCount?: InputMaybe<Scalars['Int']['input']>;
  seatingSchemaId?: InputMaybe<Scalars['String']['input']>;
  vehicleType?: InputMaybe<VehicleType_Input>;
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVehicleMakerFields_Input = {
  maker?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVehicleMakerRequest_Input = {
  fields: UpdateVehicleMakerFields_Input;
  id: Scalars['String']['input'];
};

export type UpdateVehicleMakerResponse = {
  __typename?: 'UpdateVehicleMakerResponse';
  vehicleMaker: VehicleMaker;
};

export type UpdateVehicleModelFields_Input = {
  makerId?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVehicleModelRequest_Input = {
  fields: UpdateVehicleModelFields_Input;
  id: Scalars['String']['input'];
};

export type UpdateVehicleModelResponse = {
  __typename?: 'UpdateVehicleModelResponse';
  vehicleModel: VehicleModel;
};

export type UpdateVehicleRequest_Input = {
  fields: UpdateVehicleFields_Input;
  id: Scalars['String']['input'];
};

export type UpdateVehicleResponse = {
  __typename?: 'UpdateVehicleResponse';
  vehicle: Vehicle;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  amenities: Array<Maybe<Scalars['String']['output']>>;
  baggageCapacity: Scalars['Int']['output'];
  carrierId?: Maybe<Scalars['String']['output']>;
  color: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isBranded: Scalars['Boolean']['output'];
  makerId: Scalars['String']['output'];
  modelId: Scalars['String']['output'];
  ownerId: Scalars['String']['output'];
  productionYear: Scalars['Int']['output'];
  regPlate: Scalars['String']['output'];
  seatCount: Scalars['Int']['output'];
  seatingSchemaId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  vehicleType: VehicleType;
  vin: Scalars['String']['output'];
};

export type VehicleMaker = {
  __typename?: 'VehicleMaker';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type VehicleModel = {
  __typename?: 'VehicleModel';
  id: Scalars['String']['output'];
  makerId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum VehicleType {
  Boat = 'BOAT',
  Bus = 'BUS',
  Minivan = 'MINIVAN',
  Van = 'VAN'
}

export enum VehicleType_Input {
  Boat = 'BOAT',
  Bus = 'BUS',
  Minivan = 'MINIVAN',
  Van = 'VAN'
}
